import * as React from "react";
import { Context, IContext } from "../../Context";
import {
  Card,
  Container,
  CardHeader,
  CardContent,
  Button,
  useTheme,
  TextField,
  Grid
} from "@material-ui/core";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useState, useMemo } from "react";

const MySwal = withReactContent(Swal);

// Props del componente
interface Props {}

// Componente che renderizza la pagina delle preferenze.
export const Alarms: React.SFC<Props> = (props) => {
  const context: IContext = React.useContext(Context);
  const theme = useTheme();
  const emails = context.homeConfig.alarmEmail ? context.homeConfig.alarmEmail.split(",") : [];
  const [email0, setEmail0] = useState(emails[0] || "");
  const [email1, setEmail1] = useState(emails[1] || "");
  const [email2, setEmail2] = useState(emails[2] || "");
  const [email3, setEmail3] = useState(emails[3] || "");
  const [email4, setEmail4] = useState(emails[4] || "");

  function saveMails(event) {
    event.preventDefault();
    const newConfig = {
      ...context.homeConfig,
      alarmEmail: [email0,email1, email2, email3, email4].join(",")
    }
    Axios.defaults.withCredentials = true;
    Axios.post("apiconfig", newConfig).then((res) => {
      console.log(res)
      MySwal.fire({
        title: context.i18n[context.language].swal.mailSaved,
        icon: "success",
        timer: 1500,
        customClass: `my-bg-secondary`,
        confirmButtonColor: theme.palette.primary.main,
      });
    });
  };

  return (
      <>
        <Container>
          <Card>
            <CardHeader
                title={
                  (context.i18n as any)[context.language].settings.alarms.history
                }
            />
            <CardContent>
              <Button variant="contained" onClick={deleteAlarms}>
                {" "}
                {(context.i18n as any)[context.language].settings.alarms.delete}
              </Button>
            </CardContent>
          </Card>
          <br/>
          <Card>
            <CardHeader
                title={
                  (context.i18n as any)[context.language].settings.alarms.mail
                }
            />
            <CardContent>
              <form onSubmit={saveMails}>
              <Grid container
                    direction="column"
                    alignItems="stretch"
                    spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                      fullWidth
                      label={
                        'Email 1'
                      }
                      value={email0}
                      type='email'
                      color='primary'
                      onChange={(el) => setEmail0(el.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                      fullWidth
                      label={
                        'Email 2'
                      }
                      value={email1}
                      type='email'
                      color='primary'
                      onChange={(el) => setEmail1(el.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                      fullWidth
                      label={
                        'Email 3'
                      }
                      value={email2}
                      type='email'
                      color='primary'
                      onChange={(el) => setEmail2(el.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                      fullWidth
                      label={
                        'Email 4'
                      }
                      value={email3}
                      type='email'
                      color='primary'
                      onChange={(el) => setEmail3(el.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                      fullWidth
                      label={
                        'Email 5'
                      }
                      value={email4}
                      type='email'
                      color='primary'
                      onChange={(el) => setEmail4(el.target.value)}
                  />
                </Grid>
                <br/>
                <Grid item>
                <Button type="submit" variant="contained">
                  {" "}
                  {(context.i18n as any)[context.language].alarms.dialog.save}
                </Button>
                </Grid>
              </Grid>
              </form>
            </CardContent>
          </Card>
          <br/>
        </Container>
      </>
  );

  function deleteAlarms() {
    MySwal.fire({
      title: context.i18n[context.language].swal.deleteHistory,
      icon: "question",
      showCancelButton: true,
      reverseButtons: true,
      customClass: `my-bg-secondary`,
      confirmButtonColor: theme.palette.primary.main,
    }).then((result) => {
      if (result.value) {
        Axios.defaults.withCredentials = true;
        Axios.delete("apihistoryAlarms").then((res) => {
          MySwal.fire({
            title: context.i18n[context.language].swal.historyDeleted,
            icon: "success",
            timer: 1500,
            customClass: `my-bg-secondary`,
            confirmButtonColor: theme.palette.primary.main,
          });
        });
      }
    });
  }
};