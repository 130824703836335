import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ArrowBack, Menu } from "@material-ui/icons";
import * as React from "react";
import { Context } from "../../Context";

// Props del componente
interface TitleBarProps {
  title?: string | JSX.Element | JSX.Element[];
  subtitle?: string | JSX.Element | JSX.Element[];
  onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      maring: theme.spacing(1),
      height: "100%",
      float: "right",
    },
  })
);

// Barra laterale nelle pagine Impostazioni e Scenari.
export const TitleBar: React.SFC<TitleBarProps> = (props) => {
  const { onBack, title, subtitle } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const context = React.useContext(Context);
  const classes = useStyles(theme);

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        {mobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => context.setIsSidebarOpen(true)}
          >
            <Menu />
          </IconButton>
        ) : (
          onBack && (
            <IconButton edge="start" onClick={onBack} className={classes.fab}>
              <ArrowBack />
            </IconButton>
          )
        )}
        <Typography variant="h5" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {mobile && onBack && (
          <IconButton edge="start" onClick={onBack} className={classes.fab}>
            <ArrowBack />
          </IconButton>
        )}
      </Toolbar>
      {subtitle && (
        <Toolbar>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Toolbar>
      )}
    </AppBar>
    // <div className="titlebar">
    //     {props.backButton}
    //     <div className="titlebar-title">{title}</div>
    //     <div className="titlebar-subtitle">{subtitle}</div>
    // </div>
  );
};
