import * as React from "react";

import { Context, IContext } from "../../Context";
import { HomeConfig } from "../../Interface";
import history from "../history";
import { TitleBar } from "../Layout/TitleBar";
import { Alarms } from "./Alarms";
import { Preferences } from "./Preferences";
import { Style } from "./Style";
import { Tools } from "./Tools";
import { Info } from "./Info";
import { Weather } from "./Weather";

// Props del componente
interface SettingsContainerProps {
  // changePage: Function; // Funzione per cambiare pagina
  config: HomeConfig;
  page: string; // Sezione delle impostazioni in cui si trova lo user
}

// State del componente
interface SettingsContainerState {
  pagesList: Array<string>; // Lista di sezioni contenute nelle impostazioni (preferenze, meteo...)
}

// Componente che renderizza la pagina delle impostazioni. Contiene la barra laterale del menu (che contiene la lista di sezioni
// disponibili), e la sezione selezionata delle impostazioni.
export class SettingsContainer extends React.Component<
  SettingsContainerProps,
  SettingsContainerState
> {
  constructor(props: SettingsContainerProps) {
    super(props);
    this.state = {
      pagesList: [
        // Lista di sezioni per le impostazioni
        "preferences",
        "tools",
        "weather",
        "alarms",
        "style",
        "info",
      ],
    };

    this.changePage = this.changePage.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  public goHome() {
    let list = history.location.pathname.split("/");
    list.pop();
    history.goBack();
  }

  // Funzione per cambiare lo state quando cambia la sezione tra preferenze, strumenti, meteo e allarmi.
  public changePage(changedPage: string) {
    history.push(
      history.location.pathname
        .split("/")
        .filter((a) => a !== "")
        .join("/") + `/${changedPage}`
    );
  }

  public render() {
    const context: IContext = this.context;
    return (
      <>
        <TitleBar
          title={context.i18n[context.language].settings.title}
          subtitle={
            this.props.page === "menu"
              ? undefined
              : context.i18n[context.language].settings.navbar[this.props.page]
          }
          onBack={this.props.page !== "menu" ? this.goHome : undefined}
        />
        {this.props.page === "menu" &&
          this.state.pagesList.map((el) => {
            let icon: string = "";
            let display: boolean = true;
            switch (el) {
              case "preferences":
                icon = "fa-cog";
                break;
              case "tools":
                icon = "fa-wrench";
                display = false;
                break;
              case "weather":
                icon = "fa-cloud";
                display = this.props.config.weather;
                break;
              case "alarms":
                icon = "fa-bell";
                display = this.props.config.alarms;
                break;
              case "style":
                icon = "fa-paint-brush";
                break;
              case "info":
                icon = "fa-info-circle";
                break;
            }
            if (!display) return "";

            return (
              <div
                key={"settings-key-" + el}
                className="my-bg-secondary panel-settings"
                onClick={() => this.changePage(el)}
              >
                <div className="panel-settings-icon color-accent">
                  <i className={"fa " + icon} />
                </div>
                {el === "info" && "Info"}
                {context.i18n[context.language].settings.navbar[el]}
              </div>
            );
          })}
        {this.props.page === "preferences" && (
          <Preferences
            password={false}
            textMode={this.props.config.textMode}
            languages={this.props.config.languages}
          />
        )}
        {this.props.page === "tools" && <Tools />}
        {this.props.page === "weather" && <Weather />}
        {this.props.page === "alarms" && <Alarms />}
        {this.props.page === "style" && <Style />}
        {this.props.page === "info" && <Info />}
      </>
    );
  }
}

SettingsContainer.contextType = Context;
