import {
  Box,
  Button,
  CardActions,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';

import { Context, IContext } from '../../../Context';

interface ClockProps {
  delete: Function;
}

export const Clock: React.FunctionComponent<ClockProps> = (props) => {
  const context = useContext(Context);
  const [time, setTime] = useState(moment().format('HH:mm:ss').toString());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function updateTime() {
    setTime(moment().format('HH:mm:ss').toString());
  }

  const capitalize = (str: string) => {
    return str
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardContent
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <Box position='absolute' top={theme.spacing(1)} right={theme.spacing(1)}>
        <IconButton onClick={handleClick} size='small'>
          <MoreVert />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
      </Menu>
      <Box
        height='100%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.i18n[context.language].widgets.clock.title}
        </Typography>
        <Typography
          variant='h4'
          component='h2'
          style={{
            fontVariantNumeric: 'tabular-nums',
          }}
        >
          {time}
        </Typography>
        <Typography variant='body2' component='p'>
          {capitalize(moment().format('dddd DD MMMM'))}
        </Typography>
      </Box>
    </CardContent>
  );
};
