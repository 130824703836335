import { Box, CircularProgress } from "@material-ui/core";
import React, { useContext } from "react";
import { Context } from "../../../Context";
import { SubLevel } from "../../../Interface";
import { DeviceMapOverlay } from "./DeviceMapOverlay";

interface DeviceMapProps {
  currentLevel: SubLevel;
  saveEdit: (level: SubLevel) => void;
  editing: boolean;
}

export const DeviceMap = (props: DeviceMapProps) => {
  const { images } = useContext(Context);
  const currentImage = images.find((el) => {
    if (props.currentLevel) return el.id === props.currentLevel.id;
    else return false;
  });
  let imageSrc = currentImage ? currentImage.src : "";

  return (
    <Box height="100%">
      {currentImage ? (
        <Box
          width="max-content"
          maxHeight="100%"
          maxWidth="100%"
          position="relative"
          margin="auto"
          // display="flex"
        >
          <DeviceMapOverlay
            currentLevel={props.currentLevel}
            saveEdit={props.saveEdit}
            editing={props.editing}
          />
          <img
            alt="map"
            src={imageSrc}
            style={{
              maxWidth: "100%",
              maxHeight: "calc(100vh - 392px)",
              height: "auto",
              width: "auto",
              objectFit: 'contain'
            }}
          />
        </Box>
      ) : (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
