import {
  Box,
  Button,
  Fab,
  LinearProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Stop } from "@material-ui/icons";
import React, { FunctionComponent, useContext } from "react";
import {
  blindMoveEnum,
  Device,
  RotatingBlind,
  rotationEnum,
} from "../../../../Interface";
import { Context, IContext } from "../../../../Context";

interface Props {
  device: RotatingBlind;
  updateDevice: (device: Device) => void;
}

export const RotatingBlindControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const context = useContext(Context);
  const theme = useTheme();
  const minPos = device.blind.position.min;
  const maxPos = device.blind.position.max;
  const normalisePos = (value: number) =>
    ((value - minPos) * 100) / (maxPos - minPos);
  const minRot = device.blind.rotation.min;
  const maxRot = device.blind.rotation.max;
  const normaliseRot = (value: number) =>
    ((value - minRot) * 100) / (maxRot - minRot);

  const updateMove = (key: blindMoveEnum) => {
    updateDevice({
      ...device,

      blind: {
        ...device.blind,
        move: key,
      },
    });
  };

  const updateRotation = (key: rotationEnum) => {
    let x =
      key === rotationEnum.UP
        ? device.blind.rotation.value + 10
        : device.blind.rotation.value - 10;
    const newRotation = Math.max(
      device.blind.rotation.min,
      Math.min(x, device.blind.rotation.max)
    );

    updateDevice({
      ...device,
      blind: {
        ...device.blind,
        rotation: {
          ...device.blind.rotation,
          value: newRotation,
        },
        move: blindMoveEnum.STATE,
      },
    });
  };

  return (
    <Box display="flex" justifyContent="space-around" alignItems="stretch">
      <Box
        width="150px"
        borderRadius="10px"
        padding="10px"
        display="flex"
        flexDirection="column"
        bgcolor={theme.palette.background.default}
      >
        <Button
          style={{ flexGrow: 1, marginBottom: "10px" }}
          onClick={() => updateMove(blindMoveEnum.UP)}
          variant={
            device.blind.move === blindMoveEnum.UP ? "contained" : undefined
          }
          color="primary"
        >
          <i className="fas fa-chevron-up" />
        </Button>
        <Button
          style={{ flexGrow: 1 }}
          onClick={() => updateMove(blindMoveEnum.DOWN)}
          variant={
            device.blind.move === blindMoveEnum.DOWN ? "contained" : undefined
          }
          color="primary"
        >
          <i className="fas fa-chevron-down" />
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        flexGrow="1"
        marginLeft="20px"
      >
        <Box>
          <Typography variant="button">
            {context.i18n[context.language].widgets.blindWidget.position}:{" "}
            {device.blind.position.value} %
            <LinearProgress
              variant="determinate"
              value={normalisePos(device.blind.position.value)}
            />
          </Typography>
          <br />
          <Typography variant="button">
            {context.i18n[context.language].widgets.blindWidget.rotation}:{" "}
            {device.blind.rotation.value}
            <LinearProgress
              variant="determinate"
              value={normaliseRot(device.blind.rotation.value)}
            />
          </Typography>
          <br />
          <Box height="3em">
            {moveToString(device.blind.move) && (
              <Typography variant="button" style={{ height: "1em" }}>
                {context.i18n[context.language].alarms.table.status}:<br />
                {moveToString(device.blind.move)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" margin="10px">
          <Fab
            color="primary"
            onClick={() => updateRotation(rotationEnum.DOWN)}
          >
            <i className="fas fa-undo" />
          </Fab>
          <Fab color="primary" onClick={() => updateRotation(rotationEnum.UP)}>
            <i className="fas fa-undo flip-x" />
          </Fab>
        </Box>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => updateMove(blindMoveEnum.STOP)}
        >
          <Stop /> STOP
        </Fab>
      </Box>
    </Box>
  );

  function moveToString(key: blindMoveEnum) {
    switch (key) {
      case blindMoveEnum.UP:
        return (
          <>
            <i className="ml-2 fas fa-chevron-up" />{" "}
            {context.i18n[context.language].devices.blind.movingUp}
          </>
        );
      case blindMoveEnum.DOWN:
        return (
          <>
            <i className="ml-2 fas fa-chevron-down" />{" "}
            {context.i18n[context.language].devices.blind.movingDown}
          </>
        );
      case blindMoveEnum.STOP:
        return (
          <>
            <i className="ml-2 far fa-stop-circle" />{" "}
            {context.i18n[context.language].devices.blind.stopped}
          </>
        );
    }
  }
};
