import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { Context } from '../../Context';
import { Wait } from '../../Interface';

interface WaitProps {
    device: Wait;
    showPopup: boolean;
    closePopup: Function;
    updateWait: Function;
}

interface WaitState {
    time: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export class WaitComponent extends React.Component<WaitProps, WaitState> {
    constructor(props: WaitProps) {
        super(props);
        const wait = props.device.wait / 1000;
        this.state = {
            time: wait,
            hours: Math.floor(wait / 3600),
            minutes: Math.floor((wait % 3600) / 60),
            seconds: (wait % 3600) % 60,
        };
        this.changeHour = this.changeHour.bind(this);
        this.changeMinute = this.changeMinute.bind(this);
        this.changeSeconds = this.changeSeconds.bind(this);
    }

    private changeHour(evt: React.FormEvent<HTMLInputElement>) {
        const val = Number.parseInt(evt.currentTarget.value);
        this.setState({
            time:
                (val || 0) * 3600 +
                (this.state.minutes || 0) * 60 +
                (this.state.seconds || 0),
            hours: val,
        });
    }
    private changeMinute(evt: React.FormEvent<HTMLInputElement>) {
        const val = Number.parseInt(evt.currentTarget.value);
        if (val <= 60 || isNaN(val)) {
            this.setState({
                time:
                    (this.state.hours || 0) * 3600 +
                    (val || 0) * 60 +
                    (this.state.seconds || 0),
                minutes: val,
            });
        }
    }
    private changeSeconds(evt: React.FormEvent<HTMLInputElement>) {
        const val = Number.parseInt(evt.currentTarget.value);
        if (val <= 60 || isNaN(val)) {
            this.setState({
                time:
                    (this.state.hours || 0) * 3600 +
                    (this.state.minutes || 0) * 60 +
                    (val || 0),
                seconds: val,
            });
        }
    }

    public render() {
        return (
            <Modal
                show={this.props.showPopup}
                onHide={() => this.props.closePopup()}
                size="sm"
                centered={true}
            >
                <div className="device-modal-container my-bg-secondary">
                    <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <input
                                type="number"
                                placeholder="HH"
                                className="form-control"
                                onChange={this.changeHour}
                                value={this.state.hours || ''}
                                min="0"
                                max="23"
                            />
                        </div>

                        <div className="col-md-4 mb-3">
                            <input
                                type="number"
                                placeholder="MM"
                                className="form-control"
                                onChange={this.changeMinute}
                                value={this.state.minutes || ''}
                                min="0"
                                max="59"
                            />
                        </div>

                        <div className="col-md-4 mb-3">
                            <input
                                type="number"
                                placeholder="SS"
                                className="form-control"
                                onChange={this.changeSeconds}
                                value={this.state.seconds || ''}
                                min="0"
                                max="59"
                            />
                        </div>
                    </div>
                    <br />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            width: '100%',
                        }}
                    >
                        <div>
                            <Button
                                className="my-bg-accent-green color-white"
                                variant="success"
                                style={{
                                    width: '40px',
                                }}
                                onClick={() => {
                                    this.props.updateWait(
                                        this.props.device,
                                        this.state.time * 1000,
                                    );
                                    this.props.closePopup();
                                }}
                            >
                                <i className="fas fa-check" />
                            </Button>
                        </div>
                        <div>
                            <Button
                                className="my-bg-accent-red color-white"
                                variant="danger"
                                style={{
                                    width: '40px',
                                }}
                                onClick={() => this.props.closePopup()}
                            >
                                <i className="fas fa-times" />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

WaitComponent.contextType = Context;
