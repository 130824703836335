import { Box } from "@material-ui/core";
import Axios from "axios";
import { func } from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../Context";
import { SubLevel } from "../../../Interface";
import { MapContext } from "../MapContext";
import { DevicePill } from "./DevicePill";
import { LevelPill } from "./LevelPill";

interface DeviceMapOverlayProps {
  currentLevel: SubLevel;
  saveEdit: (level: SubLevel) => void;
  editing: boolean;
}

export const DeviceMapOverlay = (props: DeviceMapOverlayProps) => {
  const context = useContext(Context);
  const [stateLevel, setStateLevel] = useState(props.currentLevel);
  const devicesLayout = stateLevel.devices;
  const levelsLayout = stateLevel.subLevels;

  useEffect(() => {
    setStateLevel(props.currentLevel);
  }, [props.currentLevel]);

  function toggleShowLabel(index: number, type: "device" | "level" = "device") {
    const newLevel: SubLevel = JSON.parse(JSON.stringify(props.currentLevel));
    if (type === "device") {
      newLevel.devices[index].layout.showLabel = !props.currentLevel.devices[
        index
      ].layout.showLabel;
    } else {
      newLevel.subLevels[index].layout.showLabel = !props.currentLevel
        .subLevels[index].layout.showLabel;
    }
    Axios.defaults.withCredentials = true;
    Axios.post("/apilevels", newLevel);
  }

  return (
    <Box
      width="100%"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      display="block"
      className="map-overlay"
    >
      {devicesLayout.map((deviceLayout, i) => {
        const device = context.allDevices.find((d) => d.id === deviceLayout.id);
        if (device) {
          return (
            <DevicePill
              device={device}
              id={`device-pill level-${stateLevel.id}`}
              layout={deviceLayout}
              key={i}
              toggleShowLabel={toggleShowLabel}
              index={i}
              editing={props.editing}
            />
          );
        }
      })}

      {levelsLayout.map((levelLayout, i) => {
        const level = context.allLevels.find((d) => d.id === levelLayout.id);
        if (level) {
          return (
            <LevelPill
              level={level}
              id={`level-pill level-${stateLevel.id}`}
              layout={levelLayout}
              key={i}
              toggleShowLabel={toggleShowLabel}
              index={i}
              editing={props.editing}
            />
          );
        }
      })}
    </Box>
  );
};
