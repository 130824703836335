import {
  Box,
  CardContent,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import * as React from "react";
import { useContext, useState } from "react";
import { Context, IContext } from "../../../Context";
import { DeviceType, DimLight, Light, RgbLight } from "../../../Interface";
import { DeviceIcon } from "../../Devices/DeviceIcon";
import { DeviceModal } from "../../NewDevices/Modals/DeviceModal";

interface LightWidgetProps {
  device: Light;
  sendUpdate: Function;
  delete: Function;
  unsetDevice: () => void;
}
export const LightWidgetComponent: React.FunctionComponent<LightWidgetProps> = (
  props
) => {
  const context: IContext = useContext(Context);
  const theme = useTheme();
  const [showPopup, setPopup] = useState(false);
  let style: React.CSSProperties | undefined;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (
    props.device.type === DeviceType.rgbLight &&
    props.device.light.status === 1
  ) {
    const device = props.device as RgbLight;
    style = {
      color: `rgb(${device.light.rgb.red},${device.light.rgb.green},${device.light.rgb.blue})`,
    };
  }
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
        <MenuItem onClick={() => props.unsetDevice()}>
          {context.i18n[context.language].widgets.menu.changeDevice}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.custom[context.language].devices.name[props.device.id]}
        </Typography>
        <div className='light-widget-container' onClick={() => setPopup(true)}>
          <div
            className={
              "light-widget-icon " +
              (props.device.light.status === 0
                ? "device-off"
                : "device-on color-accent")
            }
            style={style}
          >
            <DeviceIcon
              default={
                props.device.type === DeviceType.switch
                  ? "fas fa-plug"
                  : "fas fa-lightbulb"
              }
              status={props.device.light.status}
              icons={props.device.icons}
              className={"fa-3x"}
            />
          </div>
          {props.device.type === DeviceType.dimLight && (
            <div className='light-widget-status'>
              <div>
                {props.device.light.status === 0 ? "OFF" : "ON"}{" "}
                {props.device.type === DeviceType.dimLight &&
                  (props.device as DimLight).light.dim.value + "%"}
              </div>
              {props.device.type === DeviceType.dimLight && (
                <div>
                  <LinearProgress
                    variant='determinate'
                    value={(props.device as DimLight).light.dim.value}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </CardContent>
      <DeviceModal
        device={props.device}
        open={showPopup}
        onClose={() => setPopup(false)}
      />
    </>
  );
};
