import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { Device, DeviceType, Sensor } from "../../../Interface";
import { ValueChart } from "../../Devices/ValueChart";

export const DeviceChart: React.FunctionComponent<{ device: Device }> = (
  props
) => {
  const { device } = props;
  const [open, setOpen] = useState(false);
  if (device.type === DeviceType.scene) return <Box></Box>;
  const chartButton = (
    <Button onClick={() => setOpen(true)} color="primary" style={{ zIndex: 5 }}>
      <i className="fas fa-image fa-2x" />
    </Button>
  );
  const valuePath = ((device: Device): string => {
    switch (device.type) {
      case DeviceType.dimLight:
        return "light.dim.value";
      case DeviceType.rgbLight:
      case DeviceType.light:
        return "light.status";
      case DeviceType.rotatingBlind:
      case DeviceType.blind:
        return "blind.position.value";
      case DeviceType.thermostat:
        return "thermostat.temperature";
      case DeviceType.sensor:
        return (device as Sensor).sensor.sensorValue != undefined
          ? "sensor.sensorValue"
          : "sensor.sensorAlarm";
    }
    return "";
  })(device);
  const udm = ((device: Device) => {
    if (device.type === DeviceType.sensor) {
      const sensor = (device as Sensor).sensor;
      return sensor.sensorUdm;
    } else if (device.type === DeviceType.thermostat) {
      return "C˚";
    }
  })(device);
  const type = ((device: Device) => {
    if (
      device.type === DeviceType.light ||
      device.type === DeviceType.dimLight ||
      device.type === DeviceType.rgbLight
    ) {
      return "stepAfter";
    } else {
      return undefined;
    }
  })(device);
  return (
    <>
      {chartButton}
      <ValueChart
        open={open}
        onClose={() => setOpen(false)}
        device={device}
        valuePath={valuePath}
        udm={udm}
      />
    </>
  );
};
