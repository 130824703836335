import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Routes } from "./routes";
import history from "./components/history";
import "bootstrap";
import "./styles/main.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/omnivision.css";
import "moment/locale/it";
import "moment/locale/de";

// // Application Styles

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing
    // configuration and injects the app into a DOM element.
    const baseUrl = document.getElementById("root");
    ReactDOM.render(
        <Router history={history}>
            <Routes />
        </Router>,
        baseUrl
    );
}

renderApp();