import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Axios from 'axios';
import * as React from 'react';
import { FunctionComponent } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import authService from '../api-authorization/AuthorizeService';
const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    // color: grey[100],
    position: 'relative',
    left: '-20px',
  },
}));

export const LoginComponent: FunctionComponent<{}> = (props) => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  async function submit(event: React.FormEvent) {
    event.preventDefault();
    setError(false);
    setLoading(true);

const formData = { email: `${username}`, password: `${password}` };

 Axios.defaults.withCredentials = true;
 Axios.post(
        `/OmniVision/auth/login?useCookies=true`, formData )
     .then(async (res) => {
          if (res.status != 200) {
            setLoading(false);
            setError(true);
          } else {
            localStorage.removeItem('EmailUtente');
            localStorage.setItem('EmailUtente', username);

            setLoading(false);
            window.location.href = '/';
        }
      })
       .catch((_err) => {
            setError(true);
            setLoading(false);
      });
  }

  function handleChange(
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setRememberMe(checked);
  }
  const classes = useStyles();

  return (
    <Box
      bgcolor={theme.palette.background.paper}
      color={theme.palette.text.primary}
    >
      <Container component='main' maxWidth='xs' style={{ height: '100vh' }}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
              error={error}
              onChange={(event) => setUsername(event.target.value)}
              placeholder='Username'
              value={username}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='pws'
              label='Password'
              type='password'
              error={error}
              id='pws'
              autoComplete='current-password'
              onChange={(event) => setPassword(event.target.value)}
              value={password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleChange}
                  value='Remember me'
                  name='persistent'
                  id='persistent'
                  color='primary'
                />
              }
              label='Remember me'
            />
            {error && <Typography color='error'>Wrong credentials</Typography>}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={18}
                  className={classes.buttonProgress}
                />
              )}
              Sign In
            </Button>
            <Button onClick={() => setOpen(true)} fullWidth>
              Reset Password
            </Button>
          </form>
        </div>
        <ResetPassword open={open} setOpen={setOpen} />
      </Container>
    </Box>
  );
};

interface ResetPasswordProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ResetPassword: FunctionComponent<ResetPasswordProps> = (props) => {
  const { open, setOpen } = props;
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showError, setShowError] = React.useState('');
  const error = confirmPassword.length > 0 && confirmPassword !== newPassword;
  const theme = useTheme();
  const requestResetPassword = (e: React.FormEvent) => {
    e.preventDefault();
    if (confirmPassword !== newPassword) {
      setShowError('Password must match');
      return;
    }
    if (newPassword.length < 1) {
      setShowError('Password must be at least 1 character long');
      return;
    }
    Axios.defaults.withCredentials = true;
     Axios.post('/OmniVision/ResetPassword', [email, code, newPassword])
      .then((res) => {
        if (res.status === 200) {
          setOpen(false);
          MySwal.fire({
            icon: 'success',
            title: 'Password changed',
            background: theme.palette.background.paper,
          });
        }
      })
      .catch((err) => {
        switch (err.response.status) {
          case 404:
            setShowError('User not found');
            break;
          case 401:
            setShowError('Wrong verification Code');
            break;
        }
      });
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setShowError('');
      }}
      fullWidth
      maxWidth='sm'
    >
      <form onSubmit={requestResetPassword}>
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent>
          <TextField
            label='Email'
            autoComplete='username'
            fullWidth
            margin='normal'
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label='Code'
            fullWidth
            margin='normal'
            onChange={(e) => setCode(e.target.value)}
          />
          <TextField
            label='New Password'
            fullWidth
            autoComplete='new-password'
            margin='normal'
            type='password'
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            label='Confirm new Password'
            fullWidth
            margin='normal'
            autoComplete='confirm-new-password'
            type='password'
            error={error}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Typography color='error'>{showError}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setShowError('');
            }}
          >
            Cancel
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            Reset
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
