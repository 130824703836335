import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import axios from "axios";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context";
import {
  Blind,
  Button as DButton,
  Device,
  DeviceType,
  Light,
  Sensor,
  Thermostat,
} from "../../../Interface";
import { DeviceList } from "../../Programs/DeviceList";
import { BlindWidgetComponent } from "./BlindWidget";
import { ButtonWidgetComponent } from "./ButtonWidget";
import { LightWidgetComponent } from "./LightWidget";
import { SensorWidgetComponent } from "./SensorWidget";
import { ThermoWidgetComponent } from "./ThermoWidget";

interface DeviceWidgetProps {
  deviceID: number;
  delete: Function;
  setDevice: Function;
}

interface DeviceWidgetState {
  device?: Device;
  showPopup: boolean;
  query: string;
}

export const DeviceWidgetComponent: React.FunctionComponent<DeviceWidgetProps> = (
  props
) => {
  const context = useContext(Context);
  const theme = useTheme();
  const [device, setDevice] = useState(findDevice(props.deviceID));
  const [query, setQuery] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setDevice(findDevice(props.deviceID));
  }, [props.deviceID, context]);

  function findDevice(id: number) {
    return context.allDevices.find((dev: Device) => dev.id === id);
  }

  async function sendUpdateDevice(device: Device) {
    console.log("Updating device from widget...");
    console.log(device);
    axios.defaults.withCredentials = true;
    axios.put("apidevices", device).then((res) => {
      if (res) {
        console.log(res.status + " " + res.statusText);
        console.log("Device updated.");
        setDevice(device);
      }
    });
  }

  function searchFilter(device: Device) {
    const lowerQuery = query.toLowerCase();
    const levelId = device.path[device.path.length - 1];
    const levelName =
      context.custom[context.language].levels.name[levelId] || "";
    return (
      device.name.toLowerCase().includes(lowerQuery) ||
      levelName.toLowerCase().includes(lowerQuery)
    );
  }

  function deviceSelector() {
    return (
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => props.delete()}>
            {context.i18n[context.language].widgets.general.delete}
          </MenuItem>
        </Menu>
        <CardContent
          style={{
            position: "relative",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            position="absolute"
            top={theme.spacing(1)}
            right={theme.spacing(1)}
          >
            <IconButton onClick={handleClick} size="small">
              <MoreVert />
            </IconButton>
          </Box>
          <Typography
            color="textSecondary"
            gutterBottom
            style={{ fontSize: 14 }}
          >
            {context.i18n[context.language].widgets.deviceWidget.title}
          </Typography>
          <Button onClick={() => setShowPopup(true)} color="primary">
            {context.i18n[context.language].widgets.general.clickSelect}
          </Button>
        </CardContent>

        <Dialog
          open={showPopup}
          fullWidth
          maxWidth="sm"
          onClose={() => {
            setShowPopup(false);
          }}
        >
          <DialogTitle>
            {context.i18n[context.language].widgets.deviceWidget.deviceList}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label={context.i18n[context.language].programs.search}
              onChange={(event: any) => setQuery(event.target.value)}
              value={query}
            />
            <DeviceList
              onClick={(device) => {
                setDevice(device);
                props.setDevice(device.id);
              }}
              searchFilter={searchFilter}
              query={query}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPopup(false)}>
              {context.i18n[context.language].alarms.dialog.close}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function deviceWidget() {
    const device = findDevice(props.deviceID);
    if (device) {
      switch (device.type) {
        case DeviceType.switch:
        case DeviceType.light:
        case DeviceType.rgbLight:
        case DeviceType.dimLight:
          return (
            <LightWidgetComponent
              device={device as Light}
              sendUpdate={sendUpdateDevice}
              delete={props.delete}
              unsetDevice={() => setDevice(undefined)}
            />
          );
        case DeviceType.blind:
        case DeviceType.rotatingBlind:
          return (
            <BlindWidgetComponent
              device={device as Blind}
              sendUpdate={sendUpdateDevice}
              delete={props.delete}
              unsetDevice={() => setDevice(undefined)}
            />
          );
        case DeviceType.thermofan:
        case DeviceType.thermostat:
          return (
            <ThermoWidgetComponent
              device={device as Thermostat}
              sendUpdate={sendUpdateDevice}
              delete={props.delete}
              unsetDevice={() => setDevice(undefined)}
            />
          );
        case DeviceType.sensor:
          return (
            <SensorWidgetComponent
              device={device as Sensor}
              sendUpdate={sendUpdateDevice}
              delete={props.delete}
              unsetDevice={() => setDevice(undefined)}
            />
          );
        case DeviceType.button:
          return (
            <ButtonWidgetComponent
              device={device as DButton}
              sendUpdate={sendUpdateDevice}
              delete={props.delete}
              unsetDevice={() => setDevice(undefined)}
            />
          );
        default:
          return <span>Error</span>;
      }
    } else {
      return <span>Error</span>;
    }
  }

  if (!device) {
    return deviceSelector();
  } else {
    return deviceWidget();
  }
};
