import { Box, ButtonBase, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context";
import { Device, objectLayout } from "../../../Interface";
import { DeviceModal } from "../Modals/DeviceModal";
import { PillBadge } from "./PillBadge";
import { RenderDeviceIcon } from "../RenderDeviceIcon";
import { MapContext } from "../MapContext";

interface DevicePillProps {
  device: Device;
  layout: objectLayout;
  editing: boolean;
  id: string;
  index: number;
  toggleShowLabel: (index: number) => void;
}
export const DevicePill = (props: DevicePillProps) => {
  const { device, layout, editing, id, index } = props;
  const [open, setOpen] = useState(false);
  const showLabel = layout.layout.showLabel;
  const theme = useTheme();
  const context = useContext(Context);
  const { scenario } = useContext(MapContext);

  const name =
    context.custom[context.language].devices.name[device.id] || device.name;

  const [isScenario, setIsScenario] = useState(false);

  useEffect(() => {
    setIsScenario(scenario?.devices.some((d) => d.id === device.id) || false);
  }, [scenario]);
  return (
    <>
      <Box
        position="absolute"
        style={{
          top: `${layout.layout.top}%`,
          left: `${layout.layout.left}%`,
        }}
        onClick={() => {
          if (!editing) setOpen(true);
        }}
        data-index={index}
        className={`${id} ${editing ? "draggable" : ""}`}
      >
        <ButtonBase style={{ borderRadius: "25px" }} disabled={editing}>
          <Paper
            style={{
              borderWidth: isScenario ? "3px" : undefined,
              borderColor: theme.palette.primary.main,
              borderStyle: isScenario ? "solid" : undefined,
              height: "50px",
              borderRadius: "25px",
              minWidth: "50px",
              position: "relative",
            }}
          >
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              padding="10px"
              justifyContent="center"
            >
              <RenderDeviceIcon device={device} className="fa-2x" />
              {showLabel && (
                <Box ml={2}>
                  <Typography variant="button">{name}</Typography>
                </Box>
              )}
            </Box>
            <PillBadge device={device} />
          </Paper>
        </ButtonBase>
      </Box>
      <DeviceModal
        device={device}
        open={open}
        onClose={() => setOpen(false)}
        showLabel={showLabel}
        toggleShowLabel={() => props.toggleShowLabel(props.index)}
      />
    </>
  );
};
