import {
  Box,
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MoreVert, PlayArrow } from '@material-ui/icons';
import axios from 'axios';
import * as React from 'react';
import { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Context, IContext } from '../../../Context';
import { ScenarioInterface } from '../../../Interface';
import variable from '../../../styles/variable.scss';

const MySwal = withReactContent(Swal);

interface Props {
  scenarioID: number;
  delete: Function;
  setDevice: (id: number) => void;
}
export const ScenarioWidgetComponent: React.FunctionComponent<Props> = (
  props
) => {
  const context: IContext = React.useContext(Context);
  const [scenario, setScenario] = React.useState<ScenarioInterface | undefined>(
    findScenario(props.scenarioID, context)
  );

  React.useEffect(() => {
    setScenario(findScenario(props.scenarioID, context));
  }, [props.scenarioID]);

  if (scenario)
    return (
      <ScenarioWidget
        scenario={scenario}
        delete={props.delete}
        setScenario={setScenario}
      />
    );
  else
    return (
      <SelectScenario
        setScenario={setScenario}
        setDevice={props.setDevice}
        delete={props.delete}
      />
    );
};

const findScenario = (
  id: number,
  context: IContext
): ScenarioInterface | undefined => {
  const scenario = context.allScenarios.find((scenario) => scenario.id === id);
  return (scenario as ScenarioInterface) || undefined;
};

const executeScenario = async (id: number, context: IContext) => {
  MySwal.fire({
    title: context.i18n[context.language].swal.scenarioExecution,
    text: context.i18n[context.language].scenarios.executeText,
    icon: 'question',
    showCancelButton: true,
    reverseButtons: true,
    customClass: `my-bg-secondary`,
    confirmButtonColor: variable[context.accent],
  }).then((result) => {
    if (result.value) {
      MySwal.fire({
        title: context.i18n[context.language].swal.scenarioExecuted,
        icon: 'success',
        timer: 1500,
        customClass: `my-bg-secondary`,
        confirmButtonColor: variable[context.accent],
      });

      axios
        .head('apiscenarios/' + id)
        .then((res) => {
          if (res) {
            console.log(res.status + ' ' + res.statusText);
            console.log('Sceene executed.');
          }
        })
        .catch((err) => {
          if (err) {
            console.log('Scene not executed.');
          }
        });
    }
  });
};

interface ScenarioWidgetProps {
  scenario: ScenarioInterface;
  delete: Function;
  setScenario: (scenario?: ScenarioInterface) => void;
}

function ScenarioWidget(props: ScenarioWidgetProps) {
  const context = useContext(Context);
  const { scenario, setScenario } = props;
  const names = context.custom[context.language].scenarios.name;
  const theme = useTheme();
  const descriptions = context.custom[context.language].scenarios.description;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
        <MenuItem onClick={() => setScenario(undefined)}>
          {context.i18n[context.language].widgets.menu.changeScenario}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.i18n[context.language].widgets.scenario.title}
        </Typography>
        <Typography variant='h6' style={{ lineHeight: 1 }}>
          {scenario && names[scenario.id]}
        </Typography>
        <Typography noWrap variant='body2' component='p'>
          {scenario && descriptions[scenario.id]}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end', padding: 0 }}>
        <IconButton
          color='primary'
          onClick={() => executeScenario(props.scenario.id, context)}
        >
          <PlayArrow />
        </IconButton>
      </CardActions>
    </>
  );
}

interface SelectScenarioProps {
  setScenario: (scenario?: ScenarioInterface) => void;
  setDevice: (id: number) => void;
  delete: Function;
}

function SelectScenario(props: SelectScenarioProps) {
  const context = useContext(Context);
  const names = context.custom[context.language].scenarios.name;
  const [showPopup, setPopup] = React.useState(false);
  const { setScenario, setDevice } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.i18n[context.language].widgets.scenario.title}
        </Typography>
        <Button onClick={() => setPopup(true)} color='primary'>
          {context.i18n[context.language].widgets.general.clickSelect}
        </Button>
      </CardContent>
      <Dialog
        open={showPopup}
        fullWidth
        maxWidth='sm'
        onClose={() => {
          setPopup(false);
        }}
      >
        <DialogTitle>
          {context.i18n[context.language].widgets.scenario.scenariosList}
        </DialogTitle>
        <Divider />
        <List style={{ flexShrink: 1, minHeight: 0, overflow: 'auto' }}>
          {context.allScenarios.map((scenario: ScenarioInterface) => {
            return (
              <React.Fragment key={'scenario-' + scenario.id}>
                <ListItem
                  onClick={() => {
                    setPopup(false);
                    setScenario(scenario);
                    setDevice(scenario.id);
                  }}
                  button
                >
                  <ListItemIcon color='primary'>
                    <i className='fas fa-play' />
                  </ListItemIcon>
                  <ListItemText primary={names[scenario.id]} />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
        <DialogActions>
          <Button onClick={() => setPopup(false)}>
            {context.i18n[context.language].alarms.dialog.close}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
