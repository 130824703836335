import { Button, Card, CardContent, CardHeader, Checkbox, Container, FormControlLabel, Radio, Typography } from '@material-ui/core';
import * as React from 'react';
import { Context, IContext } from '../../Context';
import authService from '../api-authorization/AuthorizeService';
import history from '../history';
import { ChangePasswordModal } from './ChangePasswordModal';
import Axios from 'axios';

// Props del componente
interface PreferencesProps {
    password: boolean; // mostrare il pannello per la password?
    textMode: boolean;
    languages: string[];
}

// State del componente
interface PreferencesState {
    editMap: boolean;
    textMode: boolean;
    modalOpen: boolean;
}

// Componente che renderizza la pagina delle preferenze.
export class Preferences extends React.Component<
    PreferencesProps,
    PreferencesState
> {
    private editMap: boolean =
        sessionStorage.getItem('editMap') === 'true' || false;
    private textMode: boolean =
        localStorage.getItem('textMode') === 'true' || false;
    constructor(props: PreferencesProps) {
        super(props);

        this.state = {
            editMap: this.editMap,
            textMode: this.textMode,
            modalOpen: false
        };

        this.toggleMap = this.toggleMap.bind(this);
        this.toggleTextMode = this.toggleTextMode.bind(this);
        this.logoutRequest = this.logoutRequest.bind(this);
    }

    private toggleMap() {
        sessionStorage.setItem('editMap', (!this.state.editMap).toString());
        this.setState({
            editMap: !this.state.editMap
        });
    }

    private toggleTextMode() {
        localStorage.setItem('textMode', (!this.state.textMode).toString());
        this.setState({
            textMode: !this.state.textMode
        });
    }

    async logoutRequest() {
        Axios.defaults.withCredentials = true;
        Axios.get('OmniVision/auth/logout');

        setTimeout(() => {
                history.push('/');
            window.location.replace('/');
        }, 3000);
    }

    public render() {
        const context: IContext = this.context;

        let color = '';
        switch (context.accent) {
            case 'green':
                color = 'p-success-o';
                break;
            case 'blue':
                color = 'p-primary-o';
                break;
            case 'red':
                color = 'p-danger-o';
                break;
        }
        return (
            <Container>
                <Card className='m-3'>
                    <CardHeader
                        title={context.i18n[context.language].settings.preferences.editMap}
                    />
                    <CardContent>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.editMap}
                                    onChange={this.toggleMap}
                                    color='primary'
                                />
                            }
                            label={
                                context.i18n[context.language].settings.preferences
                                    .enableMapEditing
                            }
                        />
                    </CardContent>
                </Card>
                <Card className='m-3'>
                    <CardHeader
                        title={context.i18n[context.language].settings.preferences.language}
                    />
                    <CardContent>
                        {this.props.languages.includes('en') && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={context.language === 'en'}
                                            onChange={() => context.setLanguage('en')}
                                            color='primary'
                                        />
                                    }
                                    label={
                                        context.i18n[context.language].settings.preferences
                                            .languageEN
                                    }
                                />
                                <br />
                            </>
                        )}

                        {this.props.languages.includes('it') && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={context.language === 'it'}
                                            onChange={() => context.setLanguage('it')}
                                            color='primary'
                                        />
                                    }
                                    label={
                                        context.i18n[context.language].settings.preferences
                                            .languageIT
                                    }
                                />
                                <br />
                            </>
                        )}
                        {this.props.languages.includes('de') && (
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={context.language === 'de'}
                                        onChange={() => context.setLanguage('de')}
                                        color='primary'
                                    />
                                }
                                label={
                                    context.i18n[context.language].settings.preferences.languageDE
                                }
                            />
                        )}
                    </CardContent>
                </Card>

                {/* text-mode */}
                {!this.props.textMode && (
                    <Card className='m-3'>
                        <CardHeader
                            title={
                                context.i18n[context.language].settings.preferences.textMode
                            }
                        />
                        <CardContent>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.textMode}
                                        onChange={this.toggleTextMode}
                                        color='primary'
                                    />
                                }
                                label={
                                    context.i18n[context.language].settings.preferences
                                        .enableTextMode
                                }
                            />
                        </CardContent>
                    </Card>
                )}
                <Card className='m-3'>
                    <CardHeader
                        title={
                            context.i18n[context.language].settings.preferences.account
                                .description
                        }
                    />
                    <CardContent>
                        <Typography variant="subtitle1">{context.email}</Typography>
                        <br />
                        <Button
                            type='submit'
                            variant='contained'
                            className='mr-3'
                            onClick={() => this.logoutRequest()}
                        >
                            {
                                context.i18n[context.language].settings.preferences.account
                                    .logout
                            }
                        </Button>

                        <Button
                            variant='contained'
                            startIcon={<i className='fa fa-cog fa-fw' />}
                            onClick={() => {
                                this.setState({ modalOpen: true });
                            }}
                        >
                            {
                                context.i18n[context.language].settings.preferences
                                    .modifyPassword
                            }
                        </Button>
                        <ChangePasswordModal
                            open={this.state.modalOpen}
                            onClose={() => this.setState({ modalOpen: false })}
                        />
                    </CardContent>
                </Card>

                {/* Pannello password per il blocco schermo */}
                {this.props.password && (
                    <Card className='m-3'>
                        <CardHeader
                            title={
                                context.i18n[context.language].settings.preferences.password
                            }
                        />
                        <CardContent>
                            <div className='pretty p-curve p-default'>
                                <input type='checkbox' className='color-accent' />
                                <div className={`state ${color}`}>
                                    <label>
                                        {
                                            context.i18n[context.language].settings.preferences
                                                .usePassword
                                        }
                                    </label>
                                </div>
                            </div>
                            <br />
                            <br />
                            <Button
                                type='submit'
                                // style="default"
                                className='my-bg-primary no-border'
                            >
                                {
                                    context.i18n[context.language].settings.preferences
                                        .modifyPassword
                                }
                            </Button>
                        </CardContent>
                    </Card>
                )}
            </Container>
        );
    }
}

Preferences.contextType = Context;