import {
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import * as React from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Context, IContext } from "../../Context";
import {
  Blind,
  blindMoveEnum,
  Device,
  DeviceType,
  Light,
  PanelVisibilityEnum,
  QualityEnum,
  ScenarioInterface,
  statusEnum,
  Wait,
} from "../../Interface";
import { DeviceIcon } from "../Devices/DeviceIcon";
import { WaitComponent } from "../Devices/Wait";
import history from "../history";
import { DeviceModal } from "../NewDevices/Modals/DeviceModal";
import variable from "./../../styles/variable.scss";

const MySwal = withReactContent(Swal);

// Props del componente
interface ScenarioFormProps {
  scenario: ScenarioInterface; // Scenario selezionato
  handleCancel: Function; // Funzione per cancellare uno scenario
  handleExecute: Function;
  modifyScenario: Function; // Funzione per modificare uno scenario
  toDevices: Function; // Funzione per mandare alla pagina dei dispositivi
  allDevices: Device[]; // Lista di dispositivi
  new: boolean;
  addNewScenario: Function;
  printPath: Function;
}

// State del componente
interface ScenarioFormState {
  name: string; // Nome dello scenario
  description: string; // Descrizione dello scenario
  favourite: boolean; // Boolean per dire se lo scenario è preferito o no
  showPopup: boolean; // Boolean per dire se mostrare il popup per la modifica dei dispositivi in uno scenario o no
  devices: Array<Device>; // Array di dispositivi dello scenario
  currentDevice?: Device;
  validated: boolean;
}

// Componente utilizzato per mostrare uno scenario in modalità modifica.
export class ScenarioForm extends React.Component<
  ScenarioFormProps,
  ScenarioFormState
> {
  private SortableItem = SortableElement(({ device }: { device: Device }) => {
    const context = this.context;
    let icon: string = "";
    let style: React.CSSProperties | undefined;
    let classname = "";
    let status = statusEnum.ON;
    switch (device.type) {
      case DeviceType.dimLight:
      case DeviceType.rgbLight:
      case DeviceType.light:
        icon = "fas fa-lightbulb";
        status = (device as Light).light.status;
        break;
      case DeviceType.blind:
      case DeviceType.rotatingBlind:
        const deviceblind = device as Blind;
        icon = "fas fa-align-justify";
        classname = "half-icon";
        const accentColor = variable[context.accent];
        const perc = Math.round(deviceblind.blind.position.value / 25) * 25;
        const direction =
          JSON.stringify(deviceblind.icons) === JSON.stringify([81])
            ? "left"
            : "top";
        style = {
          background: `-webkit-linear-gradient(
                                    ${direction},
                                    ${accentColor} 0%,
                                    ${accentColor} ${perc}%,
                                    #606060 ${perc}%,
                                    #606060 100%
                                )`,
        };
        break;
      case DeviceType.thermofan:
      case DeviceType.thermostat:
        icon = "fas fa-thermometer-quarter";
        break;
      case DeviceType.scene:
        icon = "fas fa-image";
        break;
      case DeviceType.wait:
        icon = "fas fa-stopwatch";
        break;
    }

    return (
      <>
        <ListItem
          button
          onClick={() => {
            this.executeDevice(device);
          }}
        >
          <ListItemIcon>
            <DeviceIcon
              default={icon}
              status={status}
              icons={device.icons}
              style={style}
              className={classname}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              device.type === DeviceType.wait
                ? this.showTime((device as Wait).wait)
                : context.custom[context.language].devices.name[device.id]
            }
            secondary={this.props.printPath(device.path)}
          />
          <ListItemSecondaryAction>
            <em
              className="fas fa-minus-circle color-accent-red mr-10"
              style={{ fontSize: "20px" }}
              onClick={(event: any) => {
                event.stopPropagation();
                this.removeDevice(device);
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <Divider variant="inset" />
      </>
    );
  });

  private SortableList = SortableContainer(({ items }: { items: Device[] }) => {
    const SortableItem = this.SortableItem;
    return (
      <List dense>
        {items.map((device, index) => (
          <SortableItem key={`item-${index}`} index={index} device={device} />
        ))}
      </List>
    );
  });

  constructor(props: ScenarioFormProps) {
    super(props);
    this.state = {
      name: "",
      description: "",
      favourite: props.scenario.favourite,
      showPopup: false,
      devices: props.scenario.devices,
      currentDevice: undefined,
      validated: false,
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleChangeFavourite = this.handleChangeFavourite.bind(this);
    this.retrieveData = this.retrieveData.bind(this);
    this.changePopupState = this.changePopupState.bind(this);
    this.handleCurrentStateDevices = this.handleCurrentStateDevices.bind(this);
    this.removeDevice = this.removeDevice.bind(this);
    this.showDevice = this.showDevice.bind(this);
    this.hideDevice = this.hideDevice.bind(this);
    this.renderPopUp = this.renderPopUp.bind(this);
    this.executeDevice = this.executeDevice.bind(this);
    this.updateDevice = this.updateDevice.bind(this);
    this.addWait = this.addWait.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.updateWait = this.updateWait.bind(this);
    this.addAllLights = this.addAllLights.bind(this);
    this.addAllBlinds = this.addAllBlinds.bind(this);
    this.addAllThermostat = this.addAllThermostat.bind(this);
  }

  private showTime(time: number) {
    const val = time / 1000;

    const hours = Math.floor(val / 3600).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const minutes = Math.floor((val % 3600) / 60).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const seconds = ((val % 3600) % 60).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `${hours}:${minutes}:${seconds}`;
  }
  public componentDidUpdate(prevProps: ScenarioFormProps) {
    if (
      JSON.stringify(this.props.scenario) !== JSON.stringify(prevProps.scenario)
    ) {
      const context: IContext = this.context;
      const id = this.props.scenario.id;
      const name = context.custom[context.language].scenarios?.name[id];
      const description =
        context.custom[context.language].scenarios?.description[id];
      this.setState({
        name: name || this.props.scenario.name,
        devices: this.props.scenario.devices,
        description: description || this.props.scenario.description,
        favourite: this.props.scenario.favourite,
      });
    }

    if (
      JSON.stringify(this.props.allDevices) !==
      JSON.stringify(prevProps.allDevices)
    ) {
      const { currentDevice } = this.state;
      if (currentDevice) {
        const device = this.props.allDevices.find(
          (dev) => dev.id === currentDevice.id
        );
        this.setState({
          currentDevice: device,
        });
      }
    }
  }
  public componentDidMount() {
    const context: IContext = this.context;
    if (context.scenario) {
      const id = context.scenario.id;
      const name = context.custom[context.language].scenarios?.name[id];
      const description =
        context.custom[context.language].scenarios?.description[id];
      console.log(name, description);

      this.setState({
        name: name || context.scenario.name,
        description: description || context.scenario.description,
        devices: context.scenario.devices,
        favourite: context.scenario.favourite,
      });
    } else {
      const id = this.props.scenario.id;
      const name = context.custom[context.language].scenarios?.name[id];
      const description =
        context.custom[context.language].scenarios?.description[id];

      this.setState({
        name: name || this.props.scenario.name,
        description: description || this.props.scenario.description,
      });
    }
  }

  // Aggiorna state quando viene modificato il nome.
  private handleChangeName(e: any) {
    this.setState({
      name: e.target.value,
    });
  }

  // Aggiorna state quando la descrizione cambia.
  private handleDescriptionChange(e: any) {
    this.setState({
      description: e.target.value,
    });
  }

  // Aggiorna state quando viene cambiato il preferito.
  private handleChangeFavourite(e: any) {
    this.setState({
      favourite: !this.state.favourite,
    });
  }

  // Funzione chiamata quando si vogliono salvare le modifiche che si occupa di recuperare tutti i dati
  // e metterli in un oggetto che verrà poi mandato al backend.
  private retrieveData() {
    let scenario: ScenarioInterface = {
      id: this.props.scenario.id,
      name: this.state.name,
      description: this.state.description,
      favourite: this.state.favourite,
      devices: this.state.devices.map((device: Device) => {
        if (
          device.type !== DeviceType.blind &&
          device.type !== DeviceType.rotatingBlind
        ) {
          return device;
        } else {
          let dev = device as Blind;
          dev.blind.move = blindMoveEnum.STATE;
          return dev;
        }
      }),
      readOnly: this.props.scenario.readOnly,
    };

    return scenario;
  }

  // Mostra/Nascondi popup di modifica dei dispositivi nello scenario
  public changePopupState() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  public removeDevice(device: Device) {
    console.log("Removing device from scenario...");
    let filteredArray = this.state.devices.filter((el) => el.id !== device.id);
    this.setState({
      devices: filteredArray,
    });
    console.log("Removed device to scenario.");
  }

  // Funzione che salva nello state la lista di dispositivi nella stato attuale.
  private handleCurrentStateDevices() {
    this.setState({
      devices: this.props.allDevices.filter(
        (dev) =>
          dev.path.length > 0 &&
          dev.type !== DeviceType.scene &&
          dev.type !== DeviceType.sensor
      ),
      showPopup: !this.state.showPopup,
    });
  }

  private showDevice(device: Device) {
    this.setState({
      currentDevice: device,
    });
  }

  private hideDevice() {
    this.setState({
      currentDevice: undefined,
    });
  }

  private async updateDevice(device: Device) {
    console.log("UpdateDevice", device);
    let index = this.state.devices.map((d) => d.id).indexOf(device.id);
    let newDevices: Device[] = JSON.parse(JSON.stringify(this.state.devices));
    newDevices[index] = device;
    this.setState({
      devices: newDevices,
    });
  }

  private renderPopUp() {
    const { currentDevice } = this.state;
    if (currentDevice) {
      switch (currentDevice?.type) {
        case DeviceType.wait:
          return (
            <WaitComponent
              closePopup={this.hideDevice}
              updateWait={this.updateWait}
              device={currentDevice as Wait}
              showPopup={Boolean(currentDevice)}
            />
          );
        default:
          return (
            <DeviceModal
              open={Boolean(currentDevice)}
              onClose={this.hideDevice}
              device={currentDevice as Device}
              onSave={this.updateDevice}
            />
          );
      }
    }
  }

  public updateWait(device: Wait, time: number) {
    const devices = [...this.state.devices];
    const index = devices.indexOf(device);
    (devices[index] as Wait).wait = time;
    this.setState({
      devices,
    });
  }

  public addWait() {
    const wait: Wait = {
      id: Math.round(new Date().getTime() / 1000),
      name: "wait",
      wait: 5000,
      quality: QualityEnum.good,
      type: DeviceType.wait,
      readOnly: false,
      path: [],
      panelVisibility: PanelVisibilityEnum.PANEL_OPEN_RW,
    };
    console.log(wait);
    this.setState({
      devices: [...this.state.devices, wait],
    });
  }

  public onSortEnd({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) {
    this.setState({
      devices: arrayMove(this.state.devices, oldIndex, newIndex),
    });
  }
  public async executeDevice(device: Device) {
    if (device.type === DeviceType.wait) {
      this.showDevice(device);
      return;
    }
    const context: IContext = this.context;
    MySwal.fire({
      title: context.i18n[context.language].swal["executeDevice"],
      text: context.i18n[context.language].swal["executeText"],
      icon: "question",
      showCancelButton: true,
      reverseButtons: true,
      customClass: `my-bg-secondary`,
      confirmButtonColor: variable[context.accent],
    }).then((result) => {
      if (result.value) {
        axios
          .put("apidevices", device)
          .then((res) => {
            if (res) {
              console.log(res.status + " " + res.statusText);

              // Quando c'è un errore dal service, aggiungi a lista di errori
              // e mostra una notifica con l'errore
              if (res.data.error) {
                // let errorString = custom.name[device.name] + ": " + res.data.msg
                // this.props.addError(errorString, date)
                // toast.error(res.data.msg)
              }

              console.log("Device updated.");
              this.showDevice(device);
            }
          })
          .catch((err) => {
            // Se c'è un errore, aggiungi a lista di errori
            // e mostra una notifica di connessione
            if (err) {
              //this.props.addError(errorString, date)

              //toast.error(i18n.error.connectionError);
              console.log("Device not updated.");
            }
          });
      }
    });
  }

  public handleSubmit(
    event: React.FormEvent<HTMLFormElement>,
    dataToSend: ScenarioInterface
  ) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      if (this.props.new) {
        this.props.addNewScenario(dataToSend);
      } else {
        this.props.modifyScenario(dataToSend);
      }
    }
    this.setState({ validated: true });
  }

  private addAllLights() {
    const context: IContext = this.context;
    let filtered = this.state.devices.filter(
      (dev) =>
        dev.type !== DeviceType.light &&
        dev.type !== DeviceType.dimLight &&
        dev.type !== DeviceType.rgbLight
    );
    let lights = context.allDevices.filter(
      (dev) =>
        dev.type === DeviceType.light ||
        dev.type === DeviceType.dimLight ||
        dev.type === DeviceType.rgbLight
    );
    this.setState({
      devices: [...filtered, ...lights],
    });
  }
  private addAllBlinds() {
    const context: IContext = this.context;
    let filtered = this.state.devices.filter(
      (dev) =>
        dev.type !== DeviceType.blind && dev.type !== DeviceType.rotatingBlind
    );
    let blinds = context.allDevices.filter(
      (dev) =>
        dev.type === DeviceType.blind || dev.type === DeviceType.rotatingBlind
    );
    this.setState({
      devices: [...filtered, ...blinds],
    });
  }
  private addAllThermostat() {
    const context: IContext = this.context;
    let filtered = this.state.devices.filter(
      (dev) => dev.type !== DeviceType.thermostat
    );
    let thermostats = context.allDevices.filter(
      (dev) => dev.type === DeviceType.thermostat
    );
    this.setState({
      devices: [...filtered, ...thermostats],
    });
  }

  public render() {
    const context: IContext = this.context;
    let dataToSend: ScenarioInterface = this.retrieveData();
    return (
      <Container style={{ paddingTop: "40px" }}>
        <form
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(e, dataToSend)
          }
        >
          <TextField
            label={context.i18n[context.language].scenarios.name}
            placeholder={
              context.i18n[context.language].scenarios.placeholderName
            }
            fullWidth
            onChange={this.handleChangeName}
            value={this.state.name}
            required
            margin="normal"
          />
          <TextField
            label={context.i18n[context.language].scenarios.description}
            placeholder={
              context.i18n[context.language].scenarios.placeholderDescription
            }
            fullWidth
            value={this.state.description}
            onChange={this.handleDescriptionChange}
            margin="normal"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={this.state.favourite}
                onChange={this.handleChangeFavourite}
              />
            }
            label={context.i18n[context.language].scenarios.favourite}
          />
          <Typography variant="h6" color="textSecondary"></Typography>
          {!this.props.scenario.readOnly && (
            <>
              <ButtonGroup fullWidth size="small">
                <Button
                  size="small"
                  variant="outlined"
                  style={{ fontFamily: "inherit" }}
                  startIcon={<i className="far fa-edit" />}
                  onClick={() => this.handleCurrentStateDevices()}
                >
                  {context.i18n[context.language].scenarios.record}
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<i className="far fa-edit" />}
                  style={{ fontFamily: "inherit" }}
                  onClick={() => {
                    this.props
                      .handleExecute(this.props.scenario.id, this.props.new)
                      .then(() => {
                        const scenario: ScenarioInterface = {
                          ...this.props.scenario,
                          name: this.state.name,
                          description: this.state.description,
                          favourite: this.state.favourite,
                          devices: this.state.devices,
                        };
                        console.log(scenario);
                        context.setScenario(scenario).then(() => {
                          this.props.toDevices(this.retrieveData());
                        });
                      });
                  }}
                >
                  {context.i18n[context.language].scenarios.configure}
                </Button>
              </ButtonGroup>
              <Typography variant="h6" color="textSecondary">
                Add all:
              </Typography>
              <ButtonGroup fullWidth variant="outlined">
                <Button
                  onClick={this.addAllLights}
                  size="small"
                  style={{ fontFamily: "inherit" }}
                  startIcon={<i className="fas fa-lightbulb" />}
                >
                  {context.i18n[context.language].scenarios.allLights}
                </Button>
                <Button
                  onClick={this.addAllBlinds}
                  size="small"
                  style={{ fontFamily: "inherit" }}
                  startIcon={<i className="fas fa-align-justify" />}
                >
                  {context.i18n[context.language].scenarios.allBlinds}
                </Button>
                <Button
                  onClick={this.addAllThermostat}
                  size="small"
                  style={{
                    fontFamily: "inherit",
                  }}
                  startIcon={<i className="fas fa-thermometer-quarter" />}
                >
                  {context.i18n[context.language].scenarios.allThermostats}
                </Button>
              </ButtonGroup>
            </>
          )}

          {!this.props.scenario.readOnly && this.state.devices.length > 0 && (
            <this.SortableList
              items={this.state.devices}
              helperClass={"scenario-dragged"}
              pressDelay={500}
              onSortEnd={this.onSortEnd}
            />
          )}
          <div className="scenario-footer">
            {!this.props.scenario.readOnly && (
              <Fab
                size="small"
                color="primary"
                onClick={this.addWait}
                style={{
                  float: "left",
                }}
              >
                <i className="fas fa-stopwatch" />
              </Fab>
            )}
            <Button
              // bsStyle="default"
              onClick={() => {
                if (this.props.new) {
                  history.push("/scenarios");
                }
                this.props.handleCancel(context.setLock);
              }}
            >
              {context.i18n[context.language].scenarios.cancel}
            </Button>
            &nbsp;
            <Button
              type="submit"
              // bsStyle="default"
              color="primary"
              variant="contained"
              className="my-bg-accent no-border"
            >
              {context.i18n[context.language].scenarios.saveChanges}
            </Button>
          </div>
          {this.renderPopUp()}
        </form>
      </Container>
    );
  }
}

ScenarioForm.contextType = Context;
