import {
  Box,
  Button,
  Fab,
  LinearProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Stop } from "@material-ui/icons";
import React, { FunctionComponent, useContext } from "react";
import { Context, IContext } from "../../../../Context";
import { Blind, blindMoveEnum, Device } from "../../../../Interface";

interface Props {
  device: Blind;
  updateDevice: (device: Device) => void;
}

export const BlindControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const theme = useTheme();
  const context = useContext(Context);
  const minPos = device.blind.position.min;
  const maxPos = device.blind.position.max;
  const normalise = (value: number) =>
    ((value - minPos) * 100) / (maxPos - minPos);

  const updateMove = (key: blindMoveEnum) => {
    updateDevice({
      ...device,

      blind: {
        ...device.blind,
        move: key,
      },
    });
    console.log("Sent blind cmd to core: " + JSON.stringify(device));
  };

  return (
    <Box display="flex" justifyContent="space-around" alignItems="stretch">
      <Box
        width="150px"
        borderRadius="10px"
        padding="10px"
        display="flex"
        flexDirection="column"
        bgcolor={theme.palette.background.default}
      >
        <Button
          style={{ flexGrow: 1, minHeight: "100px", marginBottom: "10px" }}
          onClick={() => updateMove(blindMoveEnum.UP)}
          variant={
            device.blind.move === blindMoveEnum.UP ? "contained" : undefined
          }
          color="primary"
        >
          <i className="fas fa-chevron-up" />
        </Button>
        <Button
          style={{ flexGrow: 1, minHeight: "100px" }}
          onClick={() => updateMove(blindMoveEnum.DOWN)}
          variant={
            device.blind.move === blindMoveEnum.DOWN ? "contained" : undefined
          }
          color="primary"
        >
          <i className="fas fa-chevron-down" />
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        flexGrow="1"
        marginLeft="20px"
      >
        <Box>
          <Typography variant="button">
            {context.i18n[context.language].widgets.blindWidget.position}:{" "}
            {device.blind.position.value} %
            <LinearProgress
              variant="determinate"
              value={normalise(device.blind.position.value)}
            />
          </Typography>
          <br />
          <Box height="3em">
            {moveToString(device.blind.move) && (
              <Typography variant="button" style={{ height: "1em" }}>
                {context.i18n[context.language].alarms.table.status}:<br />
                {moveToString(device.blind.move)}
              </Typography>
            )}
          </Box>
        </Box>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => updateMove(blindMoveEnum.STOP)}
        >
          <Stop /> STOP
        </Fab>
      </Box>
    </Box>
  );

  function moveToString(key: blindMoveEnum) {
    switch (key) {
      case blindMoveEnum.UP:
        return (
          <>
            <i className="ml-2 fas fa-chevron-up" />{" "}
            {context.i18n[context.language].devices.blind.movingUp}
          </>
        );
      case blindMoveEnum.DOWN:
        return (
          <>
            <i className="ml-2 fas fa-chevron-down" />{" "}
            {context.i18n[context.language].devices.blind.movingDown}
          </>
        );
      case blindMoveEnum.STOP:
        return (
          <>
            <i className="ml-2 far fa-stop-circle" />{" "}
            {context.i18n[context.language].devices.blind.stopped}
          </>
        );
    }
  }
};
