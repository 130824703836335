import * as React from 'react';
import { BlindsCounter } from './BlindsCounter';
import { Clock } from './Clock';
import { DeviceWidgetComponent } from './DeviceWidget';
import { LightsCounter } from './LightsCounter';
import { ScenarioWidgetComponent } from './ScenarioWidget';
import { StopBlinds } from './StopBlinds';
import { ThermoList } from './ThermoListWidget';
import {
  DeviceWidget,
  ScenarioWidget,
  Widget,
  ThermometerWidget,
} from './Widgets';
import { Thermometer } from './Thermometer';
import { Card, makeStyles } from '@material-ui/core';

interface WidgetWrapperProps {
  value: Widget;
  index: number;
  deleteWidget: (index: number) => void;
  setDevice: (index: number, deviceId: number) => void;
}
export const WidgetWrapper: React.FunctionComponent<WidgetWrapperProps> = (
  props
) => {
  const { value, index, deleteWidget, setDevice } = props;
  return (
    <Card className='home-card'>
      {value.type.toLowerCase() === 'clock' && (
        <Clock delete={() => deleteWidget(index)} />
      )}
      {value.type.toLowerCase() === 'lightscounter' && (
        <LightsCounter delete={() => deleteWidget(index)} />
      )}
      {value.type.toLowerCase() === 'blindscounter' && (
        <BlindsCounter delete={() => deleteWidget(index)} />
      )}
      {value.type.toLowerCase() === 'stopblinds' && (
        <StopBlinds delete={() => deleteWidget(index)} />
      )}
      {value.type.toLowerCase() === 'thermolist' && (
        <ThermoList delete={() => deleteWidget(index)} />
      )}
      {value.type.toLowerCase() === 'scenario' && (
        <ScenarioWidgetComponent
          scenarioID={(value as ScenarioWidget).scenario}
          delete={() => deleteWidget(index)}
          setDevice={(newDeviceId: number) => setDevice(index, newDeviceId)}
        />
      )}

      {value.type.toLowerCase() === 'device' && (
        <DeviceWidgetComponent
          deviceID={(value as DeviceWidget).device}
          delete={() => deleteWidget(index)}
          setDevice={(newDevice: number) => setDevice(index, newDevice)}
        />
      )}
      {value.type.toLowerCase() === 'thermo' && ( // TODO: remove later
        <button onClick={() => deleteWidget(index)}>delete</button>
      )}
      {value.type.toLowerCase() === 'thermometer' && (
        <Thermometer
          deviceID={(value as ThermometerWidget).device}
          delete={() => deleteWidget(index)}
        />
      )}
    </Card>
  );
};
