import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
} from '@material-ui/core';
import * as React from 'react';

import { Context, IContext } from '../../Context';
import { Blind, Device, DeviceType, Light, statusEnum } from '../../Interface';
import { DeviceIcon } from '../Devices/DeviceIcon';
import { RenderDeviceIcon } from '../NewDevices/RenderDeviceIcon';
import variable from './../../styles/variable.scss';

interface ScenarioListProps {
  onClick: (device: Device) => void;
  isDeviceChecked?: (deviceId: number) => boolean;
  searchFilter: (device: Device) => boolean;
  query: string;
}

const regexIndexOf = function (str: string, regex: RegExp, startpos?: number) {
  var indexOf = str.substring(startpos || 0).search(regex);
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
};

const getLevelName = (path: number, context: IContext) => {
  return context.custom[context.language].levels.name[path];
};

export const DeviceList: React.SFC<ScenarioListProps> = (props) => {
  const context: IContext = React.useContext(Context);
  const regex = new RegExp(props.query, 'i');
  return (
    <List>
      {context.allDevices
        .filter((device) => device.type !== DeviceType.scene)
        .filter(props.searchFilter)
        .map((device) => {
          const name = context.custom[context.language].devices.name[device.id];
          const queryIndex = regexIndexOf(name, regex);
          let preQuery = name;
          let query = '';
          let postQuery = '';
          if (queryIndex !== -1) {
            preQuery = name.slice(0, queryIndex);
            query = name.slice(queryIndex, queryIndex + props.query.length);
            postQuery = name.slice(
              queryIndex + props.query.length,
              name.length
            );
          }
          const levelName = getLevelName(
            device.path[device.path.length - 1],
            context
          );

          let preQueryPath = '';
          let queryPath = '';
          let postQueryPath = '';
          if (levelName) {
            const queryIndexPath = regexIndexOf(levelName, regex);
            preQueryPath = levelName;
            queryPath = '';
            postQueryPath = '';
            if (queryIndexPath !== -1) {
              preQueryPath = levelName.slice(0, queryIndexPath);
              queryPath = levelName.slice(
                queryIndexPath,
                queryIndexPath + props.query.length
              );
              postQueryPath = levelName.slice(
                queryIndexPath + props.query.length,
                levelName.length
              );
            }
          }

          return (
            <div key={device.id + '-device-list'}>
              <ListItem
                dense
                button
                onClick={() => props.onClick(device)}
                role={undefined}
              >
                <ListItemIcon>
                  <RenderDeviceIcon device={device} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      {preQuery}
                      <strong>{query}</strong>
                      {postQuery}
                    </>
                  }
                />
                {levelName && (
                  <ListItemText
                    primary={
                      <span className='mr-10 float-right'>
                        <span className='widget-device-path'>
                          {preQueryPath}
                        </span>
                        <strong className='text-primary'>{queryPath}</strong>
                        <span className='widget-device-path'>
                          {postQueryPath}
                        </span>
                      </span>
                    }
                  />
                )}
                {props.isDeviceChecked && (
                  <ListItemSecondaryAction>
                    <Radio
                      edge={'start'}
                      color='primary'
                      tabIndex={-1}
                      disableRipple
                      checked={props.isDeviceChecked(device.id)}
                      onChange={() => props.onClick(device)}
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </List>
  );
};
