import { useEffect, useState } from 'react';

export function useMapSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  interface MapSize {
    width?: number;
    height?: number;
  }
  const [windowSize, setWindowSize] = useState<MapSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const mapList = document.getElementsByClassName('map-overlay');
      if(mapList.length > 0) {
        const map = mapList[0];
        const { width, height } = map.getBoundingClientRect();  
        setWindowSize({
          width,
          height,
        });
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    setTimeout(() => handleResize(), 1000);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
