import { Box, ButtonBase, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context";
import { objectLayout, SubLevel } from "../../../Interface";
import history from "../../history";
import { Holdable } from "../../Utilities/Holdable";
import icons_file from "../../../JsonDefaults/Icons.json";

interface LevelPillProps {
  level: SubLevel;
  layout: objectLayout;
  editing: boolean;
  id: string;
  toggleShowLabel: (index: number, type?: "device" | "level") => void;
  index: number;
}
export const LevelPill = (props: LevelPillProps) => {
  const { level, layout, editing, id, index } = props;
  const context = useContext(Context);
  const name =
    context.custom[context.language].levels.name[level.id] || level.name;
  const theme = useTheme();

  const showLabel = layout.layout.showLabel;
  const icons = layout.icons;
  const iconNumber = icons ? icons[0] : undefined;
  const icon: string | undefined = iconNumber
    ? (icons_file.icons as any)[iconNumber]
    : undefined;

  return (
    <>
      <Box
        position="absolute"
        style={{
          top: `${layout.layout.top}%`,
          left: `${layout.layout.left}%`,
        }}
        data-index={index}
        data-showlabel={showLabel}
        className={`${id} ${editing ? "draggable" : ""}`}
      >
        <Holdable
          onClick={() => {
            if (!editing) {
              const ids = history.location.pathname
                .split("/")
                .filter((a) => a !== "")
                .map(Number)
                .filter((a) => !isNaN(a));
              history.push(`/devices/${[...ids, level.id].join("/")}`);
            } else {
              props.toggleShowLabel(props.index, "level");
            }
          }}
          timeout={200}
        >
          <ButtonBase style={{ borderRadius: "25px" }} disabled={editing}>
            <Paper
              style={{
                height: "50px",
                borderRadius: "25px",
                minWidth: "50px",
                position: "relative",
              }}
            >
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                padding="10px"
                justifyContent="center"
              >
                <Typography color="primary">
                  {icon ? (
                    <i
                      className={`icon-${icon} fa-2x m-1`}
                      style={{ color: theme.palette.text.disabled }}
                    />
                  ) : (
                    <i
                      className="fas fa-sign-in-alt fa-2x m-1"
                      style={{ color: theme.palette.text.disabled }}
                    />
                  )}
                </Typography>
                {showLabel && (
                  <Box ml={2}>
                    <Typography variant="button">{name}</Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </ButtonBase>
        </Holdable>
      </Box>
    </>
  );
};
