import { Box, Typography } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { Context } from "../../Context";
import { Device, DeviceType, SubLevel } from '../../Interface';
import { MapContext } from './MapContext';
import { RenderDevice } from "./RenderDevice";
import { RenderSubLevel } from "./RenderSubLevel";

interface Props {
  level: SubLevel;
  navigateTo: (id: number) => void;
}

export const RenderLevelText: FunctionComponent<Props> = (props) => {
  const { level, navigateTo } = props;
  const context = useContext(Context);
  const allDevices = level.devices
    .filter((ol, i, arr) => arr.findIndex((t) => t.id === ol.id) === i) // remove duplicates
    .map((lev) => {
      const device = context.allDevices.find(
        (dev) => dev.id === lev.id
      ) as Device;
      return device;
    });
  const devices = allDevices.filter((d) => d.type !== DeviceType.scene);
  const scenes = allDevices.filter((d) => d.type === DeviceType.scene);
  // const custom = context.custom
  return (
    <>
      {level.subLevels.length > 0 && (
        <>
          <Typography variant="overline">
            {context.i18n[context.language].devices.levels}:
          </Typography>
          <Box className="devices-flexbox">
            {level.subLevels.map((lev, index) => (
              <RenderSubLevel
                sublevel={lev}
                navigateTo={navigateTo}
                key={`level-${level.id}-level-${index}`}
              />
            ))}
          </Box>
        </>
      )}
      {devices.length > 0 && (
        <>
          <Typography variant="overline">
            {context.i18n[context.language].devices.devices}:
          </Typography>
          <Box className="devices-flexbox">
            {devices.map((device, index) => {
              return (
                <RenderDevice
                  device={device}
                  key={`level-${level.id}-device-${index}`}
                />
              );
            })}
          </Box>
        </>
      )}
      {scenes.length > 0 && (
        <>
          <Typography variant="overline">
            {context.i18n[context.language].devices.scenes}:
          </Typography>
          <Box className="devices-flexbox">
            {scenes.map((scene, index) => {
              return (
                <RenderDevice
                  device={scene}
                  key={`level-${level.id}-scene-${index}`}
                />
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};
