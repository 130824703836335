import { useTheme, Theme, Badge, Box } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Blind, Device, Light, DeviceType, Sensor } from "../../Interface";
import { DeviceIcon } from "../Devices/DeviceIcon";

interface Props {
  device: Device;
  className?: string;
}

export const RenderDeviceIcon: FunctionComponent<Props> = (props) => {
  const { className, device } = props;
  const theme = useTheme();
  let status = getDeviceStatus(device);
  let defaultIcon = getDefaultIcon(device);
  let badgeContent = getBadgeContent(device);
  const color = status
    ? theme.palette.primary.main
    : theme.palette.text.disabled;

  let style: React.CSSProperties = { color };
  style = getBackground(device, theme, style);
  let key: string | undefined;
  if (
    device.type === DeviceType.blind ||
    device.type === DeviceType.rotatingBlind
  ) {
    key = (device as Blind).blind.position.value.toString();
  }
  return (
    <Badge badgeContent={badgeContent} overlap="circle" color="error">
      <Box
        style={style}
        className={style.background ? "clipped-text" : undefined}
      >
        <DeviceIcon
          className={className}
          icons={device.icons}
          status={status}
          default={defaultIcon}
        />
      </Box>
    </Badge>
  );
};

function getBackground(
  device: Device,
  theme: Theme,
  style: React.CSSProperties
): React.CSSProperties {
  if (
    device.type === DeviceType.blind ||
    device.type === DeviceType.rotatingBlind
  ) {
    const blinddevice = device as Blind;
    const accentColor = theme.palette.primary.main;
    const perc = Math.round(blinddevice.blind.position.value / 25) * 25;
    const direction =
      JSON.stringify(blinddevice.icons) === JSON.stringify([81])
        ? "left"
        : "top";
    const gradient = `${direction},${accentColor} 0%,${accentColor} ${perc}%,#606060 ${perc}%,#606060 100%`;
    return {
      ...style,
      background: `-webkit-linear-gradient(${gradient})`,
    };
  }
  return style;
}

function getDeviceStatus(device: Device): number {
  if ((device as Light).light) {
    const light = (device as Light).light;
    return light.status;
  } else if ((device as Blind).blind) {
    const blind = (device as Blind).blind;
    return Number(blind.position.value !== blind.position.min);
  } else if ((device as Sensor).sensor) {
    const sensor = (device as Sensor).sensor;
    return Number(sensor.sensorActive);
  }
  return 1;
}

function getDefaultIcon(device: Device): string {
  switch (device.type) {
    case DeviceType.light:
    case DeviceType.dimLight:
    case DeviceType.rgbLight:
      return "fas fa-lightbulb";
    case DeviceType.scene:
      return "fas fa-play-circle";
    case DeviceType.blind:
    case DeviceType.rotatingBlind:
      return "fas fa-align-justify";
    case DeviceType.sensor:
      return "far fa-bell";
    case DeviceType.thermofan:
    case DeviceType.thermostat:
      return "fas fa-thermometer-quarter";
    case DeviceType.button:
      return "fas fa-play";
    case DeviceType.switch:
      return "fas fa-plug";
  }

  return "fas fa-question-circle";
}

function getBadgeContent(device: Device): string | undefined {
  if (device.type === DeviceType.sensor) {
    const sensor = (device as Sensor).sensor;
    if (sensor.sensorAlarm) return "!";
  }
}
