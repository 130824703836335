import {
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Typography,
} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';
import * as React from 'react';

import { Context } from '../../Context';
import { Alarm, Device } from '../../Interface';
import { DeviceIcon } from '../Devices/DeviceIcon';
import { TitleBar } from '../Layout/TitleBar';

interface HistoryEvent {
    alarmHistoryId: number;
    id: number;
    date: number;
    val: number;
}

interface AlarmHistoryProps {
    allAlarms: Alarm[];
    id?: number;
    back: () => void;
}

interface AlarmHistoryState {
    history: HistoryEvent[];
    page: number;
}

export class AlarmHistory extends React.Component<
    AlarmHistoryProps,
    AlarmHistoryState
    > {
    constructor(props: AlarmHistoryProps) {
        super(props);

        this.state = {
            history: [],
            page: 0,
        };

        this.handleChangePage = this.handleChangePage.bind(this);
    }
    private rowPerPage = 10;
    async componentDidMount() {
        if (this.props.id) {
            console.log('request');
            Axios.defaults.withCredentials = true;
            Axios.get('/apihistoryAlarms/' + this.props.id)
                .then((res) => {
                    console.log(res.status + ' ' + res.statusText);

                    if (res.data.error) {
                        console.log(
                            'Error: ' + res.status + ' ' + res.data.error,
                        );
                    } else {
                        console.log(res.data);

                        this.setState({
                            history: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const allIds: number[] = this.props.allAlarms.map(
                (alarm) => alarm.id,
            );
            const newReq = this.props.id || allIds;
            console.log(allIds);
            Axios.defaults.withCredentials = true;
            Axios.post('apihistoryalarms', newReq)
                .then((res) => {
                    console.log(res.status + ' ' + res.statusText);

                    if (res.data.error) {
                        console.log(
                            'Error: ' + res.status + ' ' + res.data.error,
                        );
                    } else {
                        this.setState({
                            history: res.data || [],
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    private handleChangePage(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) {
        this.setState({
            page: newPage,
        });
    }

    render() {
        const context = this.context;
        return (
            <>
                <Container>
                    <TitleBar
                        title={context.i18n[context.language].alarms.title}
                    />
                    <Paper>
                        <Toolbar className="justify-content-between">
                            <Typography variant="h6" id="tableTitle">
                                {context.i18n[context.language].alarms.history}
                            </Typography>

                            <IconButton onClick={() => this.props.back()}>
                                <i className="fas fa-bell" />
                            </IconButton>
                        </Toolbar>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        {
                                            context.i18n[context.language]
                                                .alarms.table.device
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            context.i18n[context.language]
                                                .alarms.table.name
                                        }
                                    </TableCell>
                                    <TableCell className="ellipsis">
                                        {
                                            context.i18n[context.language]
                                                .alarms.table.description
                                        }
                                    </TableCell>
                                    <TableCell style={{ width: '120px' }}>
                                        {
                                            context.i18n[context.language]
                                                .alarms.table.status
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            context.i18n[context.language]
                                                .alarms.table.date
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.history
                                    .sort((a, b) => b.date - a.date)
                                    .slice(
                                        this.state.page * 10,
                                        this.state.page * 10 + 10,
                                    )
                                    .map((event: HistoryEvent) => {
                                        console.log(event);

                                        const alarm = this.context.allAlarms.find(
                                            (alarm: Alarm) => {
                                                console.log(alarm);
                                                return alarm.id === event.id
                                            }
                                        );

                                        let icon;
                                        if (alarm) {
                                            const device = context.allDevices.find(
                                                (device: Device) =>
                                                    device.id ===
                                                    alarm.deviceId,
                                            );

                                            if (device) {
                                                icon = (
                                                    <DeviceIcon
                                                        default="far fa-bell"
                                                        status={0}
                                                        icons={device.icons}
                                                        className={'fa-2x'}
                                                    />
                                                );
                                            }
                                        }
                                        return (
                                            <TableRow
                                                key={event.alarmHistoryId}
                                                style={{
                                                    height: '60px',
                                                }}
                                            >
                                                <TableCell align="center">
                                                    {icon}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        context.custom[
                                                            context.language
                                                        ].alarms.name[
                                                        event.id
                                                        ]
                                                    }
                                                </TableCell>
                                                <TableCell className="ellipsis">
                                                    <span>
                                                        {
                                                            context.custom[
                                                                context.language
                                                            ].alarms
                                                                .description[
                                                            event.id
                                                            ]
                                                        }
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        context.i18n[
                                                            context.language
                                                        ].alarms.table
                                                            .statusEnum[
                                                        event.val
                                                        ]
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {event.date
                                                        ? moment
                                                            .unix(event.date)
                                                            .format('L LTS')
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter className="flex-1">
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        colSpan={3}
                                        count={this.state.history.length}
                                        rowsPerPage={10}
                                        page={this.state.page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onChangePage={this.handleChangePage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </Container>
            </>
        );
    }
}

AlarmHistory.contextType = Context;
