export type WidgetType =
    | 'clock'
    | 'lightsCounter'
    | 'scenario'
    | 'blindsCounter'
    | 'thermo'
    | 'stopBlinds'
    | 'thermoList'
    | 'device'
    | 'thermometer';

interface IWidget {
    type: WidgetType;
    id: number;
}

export interface ClockWidget extends IWidget {}
export interface LightsCounterWidget extends IWidget {}
export interface BlindsCounterWidget extends IWidget {}
export interface StopBlindsWidget extends IWidget {}
export interface ThermoListWidget extends IWidget {}
export interface DeviceWidget extends IWidget {
    device: number;
}
export interface ThermometerWidget extends IWidget {
    device: number;
}

export interface ScenarioWidget extends IWidget {
    scenario: number;
}
export interface ThermoWidget extends IWidget {
    device: number;
}

export const widgetIcons = {
    Clock: 'fas fa-clock',
    'Lights Counter': 'fas fa-lightbulb',
    Scenario: 'fas fa-image',
    'Blind Counter': 'fas fa-bars',
    Thermostat: 'fas fa-thermometer-half',
    'Stop Blinds': 'fas fa-stop',
    'Thermostat List': 'fas fa-list',
    Device: 'fas fa-laptop',
    Thermometer: 'fas fa-thermometer-half',
};
export type Widget =
    | ClockWidget
    | LightsCounterWidget
    | BlindsCounterWidget
    | ScenarioWidget
    | ThermoWidget
    | StopBlindsWidget
    | ThermoListWidget
    | DeviceWidget
    | ThermometerWidget;
