import { Box, Typography, useTheme, ButtonBase } from '@material-ui/core';
import React, { FunctionComponent, useContext } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import icons_file from '../../JsonDefaults/Icons.json';

import { Context } from '../../Context';
import { objectLayout } from '../../Interface';

interface Props {
  sublevel: objectLayout;
  navigateTo: (id: number) => void;
}

export const RenderSubLevel: FunctionComponent<Props> = (props) => {
  const { sublevel, navigateTo } = props;
  const context = useContext(Context);
  const custom = context.custom;
  const theme = useTheme();
  const name = custom[context.language].levels.name[sublevel.id];
  const icons = sublevel.icons;
  const iconNumber = icons ? icons[0] : undefined;
  const icon: string | undefined = iconNumber
    ? (icons_file.icons as any)[iconNumber]
    : undefined;

  return (
    <ButtonBase
      style={{ borderRadius: '10px' }}
      component='div'
      onClick={() => navigateTo(sublevel.id)}
    >
      <Box
        className='devices-card'
        bgcolor={theme.palette.background.paper}
        boxShadow={theme.shadows[1]}
      >
        {icon ? (
          <i
            className={`icon-${icon} fa-2x m-1`}
            style={{ color: theme.palette.text.disabled }}
          />
        ) : (
          <i
            className='fas fa-sign-in-alt fa-2x m-1'
            style={{ color: theme.palette.text.disabled }}
          />
        )}

        <Typography variant='caption'>
          <LinesEllipsis
            style={{ whiteSpace: 'pre-wrap' }}
            text={name}
            maxLine='2'
            trimRight={false}
            basedOn='words'
          />
        </Typography>
      </Box>
    </ButtonBase>
  );
};
