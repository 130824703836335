export enum DeviceType {
  blind = 0,
  rotatingBlind,
  light,
  dimLight,
  rgbLight,
  sensor,
  scene,
  thermostat,
  thermofan,
  weatherStation,
  wait,
  button,
  switch,
}

export interface Switch extends Light {}

export interface Light extends IDevice {
  light: {
    status: statusEnum;
  };
}

export interface Button extends IDevice {
  light: {
    status: statusEnum;
  };
}

export interface DimLight extends Light {
  light: {
    status: statusEnum;
      dim: StdValue;
      kelvin?: StdValue;
  };
}

export interface RgbLight extends Light {
  light: {
    status: statusEnum;
    rgb: RGB;
    dim?: StdValue;
  };
}

export interface Scene extends IDevice {
  scene: {
    sceneList: number[];
  };
}

export interface Thermofan extends IDevice {
  thermofan: {
    status: statusEnum;
    setpoint?: StdValue;
    fanSpeed?: StdValue;
    //temperature?: number;
  };
}

export interface Thermostat extends IDevice {
  thermostat: {
    condensationAlarm?: boolean;
    fanMode?: fanModeEnum;
    fanSpeed?: StdValue;
    humidity?: number;
    model?: string;
    occupancy: occupancyEnum;
    offset?: StdValue;
    season?: seasonEnum;
    setpoint: StdValue;
    status?: statusEnum;
    temperature: number;
    thermoMode: thermoModeEnum;
    windowAlarm?: boolean;
  };
}

export interface WeatherStation extends IDevice {
  weather: {
    qualityRadio: string[];
    quality: string;
    airPressure: Pressure;
    frost: boolean;
    globalRadiation: number;
    relativeHumidity: number;
    lightIntensity: LightIntensity;
    rain: boolean;
    temperature?: number;
    twilight: number;
    wind: Wind;
  };
}

export interface Blind extends IDevice {
  blind: {
    position: StdValue;
    move: blindMoveEnum;
  };
}

export interface RotatingBlind extends Blind {
  blind: {
    position: StdValue;
    rotation: StdValue;
    move: blindMoveEnum;
  };
}

export interface RGB {
  red: number;
  green: number;
  blue: number;
  white: number;
}

export interface LevelsObject {
  startLevels: number[];
  subLevels: SubLevel[];
}

export interface SubLevel {
  id: number; // Id del livello
  name: string; // Nome del livello
  devices: objectLayout[]; // Array di id dei devices presenti nel livello
  subLevels: objectLayout[]; // Array di id-layout dei "sottolivelli" presenti nel livello
}

export interface IDevice {
  id: number;
  name: string;
  quality: QualityEnum;
  path: number[];
  readOnly: boolean;
  panelVisibility: PanelVisibilityEnum;
  type: DeviceType;
  icons?: number[];
}

export interface Sensor extends IDevice {
  sensor: {
    sensorDisconnect?: boolean;
    sensorActive: boolean;
    sensorAlarm: boolean;
    sensorValue?: number;
    sensorUdm?: string;
    sensorTxtConvert?: { txt: string; val: number }[];
  };
}

export interface Wait extends IDevice {
  wait: number;
}

export interface ErrorDevice extends IDevice {}

export type Device =
    | Blind
    | RotatingBlind
    | Light
    | DimLight
    | RgbLight
    | Scene
    | Thermofan
    | Thermostat
    | ErrorDevice
    | Wait
    | Sensor
    | Button
    | Switch;

export interface Layout {
  showLabel: boolean;
  top: number;
  left: number;
}

export interface objectLayout {
  id: number;
  icons?: number[];
  layout: Layout;
}

export interface StdValue {
  value: number;
  max: number;
  min: number;
}

export interface ScenarioInterface {
  id: number;
  newScenario?: boolean;
  name: string;
  description: string;
  favourite: boolean;
  devices: Device[];
  readOnly: boolean;
}

// Interfaccia per un allarme
export interface Alarm {
  alarmId: number;
  id: number;
  deviceId: number;
  persistent: boolean;
  date: number | null;
  dateSeen: number | null;
  sendby: string | null;
  almdisable: boolean | false;
  delay: number;
  status: boolean;
  condition: string;
}

// Interfaccia per il file di configurazione della home
export interface HomeConfig {
  devices: boolean;
  scenarios: boolean;
  programs: boolean;
  alarms: boolean;
  weather: boolean;
  settings: boolean;
  cleanScreen: boolean;
  lockScreen: boolean;
  textMode: boolean;
  languages: string[];
  languageDefault: string;
  alarmEmail: string | null
}

export interface Pressure {
  absolute: number;
  relative: number;
}

export interface Wind {
  direction: number;
  speed: number;
}

export interface LightIntensity {
  east: number;
  southEast: number;
  south: number;
  southWest: number;
  west: number;
  northEast: number;
  north: number;
  northWest: number;
}

export enum QualityEnum {
  bad,
  badConfigurationError,
  badNotConnected,
  badDeviceFailure,
  badSensorFailure,
  badLastKnownValue,
  badCommFailure,
  badOutOfService,
  badWaitingForInitialData,
  uncertain,
  uncertainLastUsableValue,
  uncertainSensorNotAccurate,
  uncertainEUExceeded,
  uncertainSubNormal,
  good,
  goodLocalOverride,
}

export enum PanelVisibilityEnum {
  PANEL_HIDE = 1,
  PANEL_OPEN_R = 2,
  PANEL_OPEN_RW = 3,
}

export enum statusEnum {
  NULL = -1,
  OFF = 0,
  ON = 1,
}

export enum blindMoveEnum {
  NULL = -1,
  OFF = 0,
  ON,
  DOWN,
  UP,
  STOP,
  STATE,
}

export enum rotationEnum {
  UP,
  DOWN,
}

export enum fanModeEnum {
  UNDEFINED = -2,
  AUTO = -1,
  OFF = 0,
  MIN = 1,
  MED = 2,
  MAX = 3,
}

// export enum fanModeEnum {
//   NULL = -1,
//   AUTOMATIC = 0,
//   MIN = 1,
//   MAX = 2,
//   MED = 3,
//   NIGHT = 4,
//   OFF = 5,
// }

export enum occupancyEnum {
  NULL = -1,
  ECO = 0,
  COMFORT = 1,
}

export enum seasonEnum {
  NULL = -1,
  SPRING = 0,
  SUMMER = 1,
  AUTUMN = 2,
  WINTER = 3,
}

export enum thermoModeEnum {
  NULL = -1,
  HEAT = 0,
  COOL = 1,
}

// Interfaccia per un errore di connessione
export interface Error {
  id: number;
  error: string;
  date: string;
}

// Interfaccia per un elemento dentro la lista dello storico
export interface History {
  timestamp: number;
  value: number;
}

export interface Event {
  id: number;
  title: string;
  description: string;
  eventDateTime: number;
  type: string;
  device?: Device;
  scenario?: number[];
  repeatEvent?: RepeatedEvent;
  disabled?: boolean;
}

export interface RepeatedEvent {
  startDate?: number;
  endDate?: number;
  weekDays: Day[];
}

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export interface TaskEvent {
  id: number;
  dateTime: string;
  isRepeat: boolean;
  title: string;
  description: string;
}

export interface MapImage {
  id: number;
  src: string;
}

export type StringDict = {
  [key: string]: string;
};
export type Languages = 'it' | 'de' | 'en';
export type Custom = {
  [key in Languages]: {
    devices: {
      name: StringDict;
    };
    levels: {
      name: StringDict;
    };
    alarms: {
      name: StringDict;
      description: StringDict;
    };
    scenarios: {
      name: StringDict;
      description: StringDict;
    };
    programs: {
      name: StringDict;
      description: StringDict;
    };
  };
};

export type ObjectMap<T> = {
  [key in string | number]: T;
};
