import {
  Box,
  Button as MButton,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';
import { Button, Device, Light } from '../../../../Interface';

interface Props {
  device: Button;
  updateDevice: (device: Device) => void;
}

export const ButtonControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const onDevice: Light = JSON.parse(JSON.stringify(device));
  onDevice.light.status = 1;
  const offDevice: Light = JSON.parse(JSON.stringify(device));
  offDevice.light.status = 0;
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true);
    updateDevice(onDevice);
    setTimeout(() => {
      updateDevice(offDevice);
      setLoading(false);
    }, 1000);
  };
  return (
    <Box display='flex' justifyContent='center'>
      <Box p={5}>
        <MButton
          color='primary'
          onClick={onClick}
          variant='contained'
          disabled={loading}
          style={{ height: '150px', width: '150px' }}
        >
          {loading ? (
            <CircularProgress  />
          ) : (
            <PlayArrow fontSize='large' />
          )}
        </MButton>
      </Box>
    </Box>
  );
};
