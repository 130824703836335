import * as React from 'react';
import {
    List,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    Divider,
    ListItem,
} from '@material-ui/core';
import { ScenarioInterface } from '../../Interface';
import { Context, IContext } from '../../Context';

interface Props {
    clickCheckBox: (scenario: ScenarioInterface) => void;
    isScenarioChecked: (scenarioId: number) => boolean;
}

export const ScenarioList: React.FunctionComponent<Props> = (props) => {
    const context: IContext = React.useContext(Context);
    return <List>
        {context.allScenarios.map((scenario) => {
            const name = context.custom[context.language].scenarios?.name[scenario.id] || scenario.name;

            return (
                <div key={scenario.id + '-scenario-list'}>
                    <ListItem
                        dense
                        button
                        onClick={() => props.clickCheckBox(scenario)}
                        role={undefined}
                    >
                        <ListItemIcon>
                            <i className="fas fa-play color-accent" />
                        </ListItemIcon>
                        <ListItemText primary={name} />
                        <ListItemSecondaryAction>
                            <Checkbox
                                edge={'start'}
                                color="primary"
                                tabIndex={-1}
                                disableRipple
                                checked={props.isScenarioChecked(
                                    scenario.id,
                                )}
                                onChange={() =>
                                    props.clickCheckBox(scenario)
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </div>
            );
        })}
    </List>;
};
