import {
  Box,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@material-ui/core';
import { PlayArrow, PlayCircleFilled } from '@material-ui/icons';
import React, { FunctionComponent, useContext, useState } from 'react';
import { Context } from '../../../../Context';
import { Device, DeviceType, Scene } from '../../../../Interface';
import { DeviceIcon } from '../../../Devices/DeviceIcon';
import { RenderDeviceIcon } from '../../RenderDeviceIcon';
import { DeviceModal } from '../DeviceModal';

interface Props {
  device: Scene;
  updateDevice: (device: Device) => void;
}

export const SceneControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  if (device.scene.sceneList.length > 1) {
    return <MultiSceneControl device={device} updateDevice={updateDevice} />;
  } else {
    return <SingleSceneControl device={device} updateDevice={updateDevice} />;
  }
};

const SingleSceneControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  return (
    <Box display='flex' justifyContent='center'>
      <Fab color='primary' size='large' onClick={() => updateDevice(device)}>
        <PlayArrow />
      </Fab>
    </Box>
  );
};

const MultiSceneControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const context = useContext(Context);
  const [currentDevice, setCurrentDevice] = useState<number | undefined>();
  const theme = useTheme();
  return (
    <Box
      bgcolor={theme.palette.background.default}
      borderRadius={5}
      boxShadow={theme.shadows}
    >
      <List>
        {device.scene.sceneList.map((id) => {
          const sceneIndex = context.allDevices.findIndex(
            (dev) => dev.id === id
          );
          const sceneDevice = context.allDevices[sceneIndex];
          if (sceneDevice) {
            const name =
              context.custom[context.language].devices.name[sceneDevice.id];
            return (
              <ListItem
                button
                onClick={() => {
                  if (sceneDevice.type === DeviceType.scene) {
                    updateDevice(sceneDevice);
                  } else {
                    setCurrentDevice(sceneIndex);
                  }
                }}
                key={'scene' + id}
              >
                <ListItemIcon>
                  <RenderDeviceIcon device={sceneDevice} className='fa-2x' />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            );
          } else {
            return <></>;
          }
        })}
        {currentDevice && (
          <DeviceModal
            device={context.allDevices[currentDevice]}
            open={currentDevice != undefined}
            onClose={() => setCurrentDevice(undefined)}
          />
        )}
      </List>
    </Box>
  );
};
