import {
  Box,
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Axios from 'axios';
import * as React from 'react';
import { Card, Dropdown, Modal } from 'react-bootstrap';
import { Context, IContext } from '../../../Context';
import { Blind, Device, DeviceType } from '../../../Interface';
import variable from '../../../styles/variable.scss';
import { DeviceIcon } from '../../Devices/DeviceIcon';
import { DeviceModal } from '../../NewDevices/Modals/DeviceModal';

interface BlindsCounterProps {
  delete: Function;
}

function filterDevices(dev: Device) {
  return dev.type === DeviceType.blind || dev.type === DeviceType.rotatingBlind;
}
function filterBlinds(dev: Device) {
  const blind: Blind = dev as Blind;
  return blind.blind.position.value !== 100;
}

export const BlindsCounter: React.FunctionComponent<BlindsCounterProps> = (
  props
) => {
  const context: IContext = React.useContext(Context);
  const blinds = [
    ...context.allDevices.filter(filterDevices).filter(filterBlinds),
  ];
  const [showPopup, setPopup] = React.useState(false);
  const [showDevice, setDevice] = React.useState<number | undefined>(undefined);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const findDevice = (id: number) => {
    const dev = context.allDevices.find((dev) => dev.id === id);
    return (dev as Blind) || (({} as unknown) as Blind);
  };

  const printPath = (path: number[]) => {
    let names: string[] = [];
    path.forEach((el) => {
      context.allLevels.forEach((found) => {
        if (el === found.id)
          names.push(context.custom[context.language].levels.name[found.id]);
      });
    });
    names.shift();
    return <span className='widget-device-path'>{names.join(' \u2192 ')}</span>;
  };

  const accentColor = variable[context.accent];

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.i18n[context.language].widgets.blindsCounter.title}
        </Typography>
        <Typography>
          {context.i18n[context.language].widgets.general.thereAre}
          <Typography
            component='span'
            color='primary'
            style={{ margin: '10px', fontWeight: 'bold' }}
          >
            {blinds.length}
          </Typography>
          {context.i18n[context.language].widgets.blindsCounter.blindsOpen}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        {blinds.length > 0 && (
          <Button onClick={() => setPopup(true)} color='primary'>
            {context.i18n[context.language].widgets.general.show}
          </Button>
        )}
      </CardActions>
      <Dialog
        open={showPopup}
        onClose={() => {
          setPopup(false);
          setDevice(undefined);
        }}
        scroll='paper'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {context.i18n[context.language].widgets.blindsCounter.blindsList}
        </DialogTitle>
        <DialogContent>
          <List>
            {blinds.map((blind) => {
              const perc =
                Math.round((blind as Blind).blind.position.value / 25) * 25;
              return (
                <React.Fragment key={'blinds-' + blind.id}>
                  <ListItem
                    onClick={() => {
                      setPopup(false);
                      setDevice(blind.id);
                    }}
                    button
                  >
                    <ListItemIcon>
                      <DeviceIcon
                        default='fas fa-align-justify'
                        status={1}
                        icons={blind.icons}
                        style={{
                          background: `-webkit-linear-gradient(
                                top,
                                ${accentColor} 0%,
                                ${accentColor} ${perc}%,
                                #606060 ${perc}%,
                                #606060 100%
                            )`,
                        }}
                        className={'half-icon fa-2x'}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        context.custom[context.language].devices.name[blind.id]
                      }
                      secondary={printPath(blind.path)}
                    />
                  </ListItem>
                  <Divider variant='inset' />
                </React.Fragment>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopup(false)}>
            {context.i18n[context.language].alarms.dialog.close}
          </Button>
        </DialogActions>
      </Dialog>
      {showDevice && (
        <DeviceModal
          device={findDevice(showDevice)}
          open={true}
          onClose={() => {
            setPopup(true);
            setDevice(undefined);
          }}
        />
      )}
    </>
  );
};
