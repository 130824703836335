import {
  Box,
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Axios from 'axios';
import * as React from 'react';
import { Card, Dropdown, Modal } from 'react-bootstrap';
import { Context, IContext } from '../../../Context';
import {
  Device,
  DeviceType,
  occupancyEnum,
  Thermostat,
} from '../../../Interface';
import variable from '../../../styles/variable.scss';
import { DeviceIcon } from '../../Devices/DeviceIcon';
import { DeviceModal } from '../../NewDevices/Modals/DeviceModal';

interface ThermoListProps {
  delete: Function;
}

function filterDevices(dev: Device) {
  return dev.type === DeviceType.thermostat;
}
export const ThermoList: React.SFC<ThermoListProps> = (props) => {
  const context: IContext = React.useContext(Context);
  const thermos = [...context.allDevices.filter(filterDevices)];
  const [showPopup, setPopup] = React.useState(false);
  const [showDevice, setDevice] = React.useState<number | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const sendUpdateDevice = async (device: Device) => {
    console.log('Updating device...');

    Axios.defaults.withCredentials = true;
    Axios.put('/apidevices', device).then((res) => {
      if (res) {
        console.log(res.status + ' ' + res.statusText);
        // Quando c'è un errore dal service, aggiungi a lista di errori
        // e mostra una notifica con l'errore

        console.log('Device updated.');
      }
    });
  };

  const findDevice = (id: number) => {
    const dev = context.allDevices.find((dev) => dev.id === id);
    return (dev as Thermostat) || (({} as unknown) as Thermostat);
  };

  const printPath = (path: number[]) => {
    let names: string[] = [];
    path.forEach((el) => {
      context.allLevels.forEach((found) => {
        if (el === found.id)
          names.push(context.custom[context.language].levels.name[found.id]);
      });
    });
    names.shift();
    return <span className='widget-device-path'>{names.join(' \u2192 ')}</span>;
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.i18n[context.language].widgets.thermostat.title}
        </Typography>
        <Typography noWrap>
          {context.i18n[context.language].widgets.general.thereAre}
          <Typography
            component='span'
            color='primary'
            style={{ margin: '10px', fontWeight: 'bold' }}
          >
            <b>{thermos.length}</b>
          </Typography>
          {context.i18n[context.language].widgets.thermostat.thermostats}
        </Typography>
      </CardContent>

      {thermos.length > 0 && (
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={() => setPopup(true)} color='primary'>
            {context.i18n[context.language].widgets.general.show}
          </Button>
        </CardActions>
      )}
      <Dialog
        open={showPopup}
        onClose={() => {
          setPopup(false);
          setDevice(undefined);
        }}
        scroll='paper'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {context.i18n[context.language].widgets.thermostat.thermostatsList}
        </DialogTitle>
        <DialogContent>
          <List>
            {!showDevice &&
              thermos.map((thermo) => {
                const thermostatdevice = thermo as Thermostat;
                const temp = thermostatdevice.thermostat.temperature;
                return (
                  <React.Fragment key={'thermo-' + thermostatdevice.id}>
                    <ListItem
                      onClick={() => {
                        setPopup(false);
                        setDevice(thermo.id);
                      }}
                      button
                    >
                      <ListItemIcon>
                        <DeviceIcon
                          default='fas fa-thermometer-quarter'
                          status={1}
                          icons={thermo.icons}
                          style={{
                            fontSize: '15pt',
                          }}
                          className={'fa-2x'}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          context.custom[context.language].devices.name[
                            thermo.id
                          ]
                        }
                        secondary={printPath(thermo.path)}
                      />
                      {temp && (
                        <div
                          style={{
                            display: 'inline-block',
                            margin: '10px',
                            padding: '0 5px',
                            borderRadius: '3px',
                          }}
                          className='color-white my-bg-accent'
                        >
                          {temp + ' ˚C'}
                        </div>
                      )}
                      {occupancyEnum[thermostatdevice.thermostat.occupancy]}
                    </ListItem>
                    <Divider variant='inset' />
                  </React.Fragment>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopup(false)}>
            {context.i18n[context.language].alarms.dialog.close}
          </Button>
        </DialogActions>
      </Dialog>
      {showDevice && (
        <DeviceModal
          device={findDevice(showDevice)}
          open={true}
          onClose={() => {
            setPopup(true);
            setDevice(undefined);
          }}
        />
      )}
    </>
  );
};
