import {Box, Fab, Slider, Typography, useTheme} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Device, RgbLight, statusEnum } from '../../../../Interface';
import { ColourWheel } from '../../../Utilities/ColourWheel';
import { ToggleButton } from '../ToggleButton';
import { useStyles } from './DimLightControl'
import {Add, Remove} from "@material-ui/icons";

interface Props {
  device: RgbLight;
  updateDevice: (device: Device) => void;
}



export const RgbLightControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const status = device.light.status ? 'ON' : 'OFF';
  const changeStatus = (value: string) => {
    const newDevice: Device = {
      ...device,
      light: {
        ...device.light,
        status: value === 'ON' ? 1 : 0,
      },
    };
    updateDevice(newDevice);
  };

  let timeout: NodeJS.Timeout | undefined;
  const dim = device.light.dim;
  const [active, setActive] = useState(false)
  const [stateValue, setStateValue] = useState(dim?.value);

  const changeDim = (value: number) => {
    value = Math.max(0, Math.min(100, Number(value)));
    if(!device.light.dim) return;
    if(timeout) {
      clearTimeout(timeout)
    }
    setActive(true)
    const newDevice: RgbLight = {
      ...device,
      light: {
        ...device.light,
        dim: {
          ...device.light.dim,
          value,
        },
        status: value === 0 ? statusEnum.OFF : statusEnum.ON,
      },
    };
    setStateValue(value);
      updateDevice(newDevice);
      console.log("Sent light dim cmd to core(change state): " + JSON.stringify(newDevice));
    timeout = setTimeout(() => setActive(false), 5000)
  };

  const onColourSelected = (rgb: string) => {
    const regex = /rgb\((\d+), (\d+), (\d+)\)/;
    const match = regex.exec(rgb);
    if (!match) return;
    const [_, ...matchTail] = match;
    const [red, green, blue] = matchTail.map(Number);
    const newDevice: Device = {
      ...device,
      light: {
        ...device.light,
        status: statusEnum.ON,
        rgb: {
          ...device.light.rgb,
          red,
          green,
          blue,
        },
      },
    };
      updateDevice(newDevice);
      console.log("Sent light dim cmd to core(change color): " + JSON.stringify(newDevice));
  };
  const deviceRgb = device.light.rgb;
  const rgbColorString =
    device.light.status === statusEnum.ON
      ? `rgb(${deviceRgb.red}, ${deviceRgb.green}, ${deviceRgb.blue})`
      : undefined;
  const [colourWheel, setColourWheel] = useState<ColourWheel | null>(null);

  useEffect(() => {
    if (colourWheel) {
      console.log('ok');
      if (device.light.status === statusEnum.OFF) colourWheel.clear();
      else {
        colourWheel.setState(
          {
            rgb: {
              r: deviceRgb.red,
              g: deviceRgb.green,
              b: deviceRgb.blue,
            },
            innerWheelOpen: true,
            centerCircleOpen: true,
          },
          () => {
            colourWheel.drawInnerWheel();
            colourWheel.drawCenterCircle();
          }
        );
      }
    }
  }, [device.light.status, colourWheel]);

  useEffect(() => {
    if(!active) {
      setStateValue(dim?.value);
    }
  }, [device.light.dim?.value, device.light.status, active]);

  return (
      <Box
          height="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
      >
    <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
    >
        <Box display="flex" justifyContent="center" padding={1}>
            <Box
                position='relative'
                display='flex'
                justifyContent='center'
            >
                <ColourWheel
                    radius={110}
                    lineWidth={35}
                    padding={5}
                    onRef={(ref: ColourWheel) => setColourWheel(ref)}
                    onColourSelected={onColourSelected}
                    animated
                    spacers={{
                        colour: theme.palette.text.disabled,
                        shadowColour: 'black',
                        shadowBlur: 5,
                    }}
                    preset={device.light.status === statusEnum.ON}
                    presetColour={rgbColorString}
                />
            </Box>
        </Box>
        <Box display="flex" justifyContent="center" padding={3}>
        <Box width="150px">
            <ToggleButton
                value={status}
                onChange={changeStatus}
                labels={["ON", "OFF"]}
                horizontal
            />
        </Box>
        </Box>
    </Box>
        {dim &&
            <Box display="flex" justifyContent="center">
              <Box display="flex" justifyContent="center" maxHeight="69%">
              <Box
                  justifyContent="center"
              >
                <Slider
                    orientation="vertical"
                    min={dim?.min}
                    max={dim?.max}
                    classes={classes}
                    valueLabelDisplay="on"
                    value={stateValue}
                    onChange={(_e, v) => setStateValue(v as number)}
                    onChangeCommitted={(_e, v) => changeDim(v as number)}
                />
                <Typography style={{width: "50px", textAlign: "center"}}>
                  {stateValue}%
                </Typography>
              </Box>
              <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
              >
                <Fab
                    size="small"
                    color="primary"
                    onClick={() => changeDim(dim?.value + 1)}
                >
                  <Add />
                </Fab>
                <Fab
                    size="small"
                    color="primary"
                    onClick={() => changeDim(dim?.value - 1)}
                >
                  <Remove />
                </Fab>
              </Box>
              </Box>
            </Box>
        }
      </Box>
  );
};
