import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  fade,
  Icon,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  useTheme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { Fragment, useContext, useState } from 'react';
import { Context } from '../../../Context';

interface LevelSelectionProps {
  open: boolean;
  onClose: () => void;
  moveDevice: (id: number) => Promise<unknown>;
}
export const LevelSelection = (props: LevelSelectionProps) => {
  const context = useContext(Context);
  const theme = useTheme();
  const [query, setQuery] = useState('');
  const { open, onClose, moveDevice } = props;
  const [selected, setSelected] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        {context.i18n[context.language].devices.levels}:
      </DialogTitle>
      <DialogContent>
        <Box bgcolor={fade(theme.palette.common.white, 0.15)} marginBottom={2}>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            placeholder={context.i18n[context.language].programs.search}
            value={query}
            onChange={(ev) => setQuery(ev.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List
          style={{
            height: '380px',
            overflow: 'auto',
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          {context.allLevels
            .map((l) => ({
              id: l.id,
              name: context.custom[context.language].levels.name[l.id],
              path: getPath(l.id),
            }))
            .map(({ path, ...rest }) => ({
              path: path.map(idToLevelName),
              ...rest,
            }))
            .filter(({ name }) =>
              name.toLowerCase().includes(query.toLowerCase())
            )
            .map(({ id, name, path }) => {
              return (
                <Fragment key={id}>
                  <ListItem
                    button
                    selected={id === selected}
                    onClick={() => setSelected(id)}
                  >
                    <ListItemIcon>
                      <Radio checked={id === selected} color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={name} secondary={path.join(' / ')} />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {context.i18n[context.language].alarms.dialog.close}
        </Button>
        <Button
          disabled={!selected}
          color='primary'
          variant='contained'
          startIcon={
            loading ? (
              <CircularProgress size={16} style={{ color: 'white' }} />
            ) : undefined
          }
          onClick={async () => {
            setLoading(true);
            if (selected) await moveDevice(selected);
            setLoading(false);
            onClose();
          }}
        >
          {context.i18n[context.language].alarms.dialog.save}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function getPath(id: number, acc: number[] = []) {
    for (let level of context.allLevels) {
      for (let sl of level.subLevels) {
        if (id === sl.id) {
          return getPath(level.id, [level.id, ...acc]);
        }
      }
    }
    return acc;
  }

  function idToLevelName(id: number) {
    return context.custom[context.language].levels.name[id] || '';
  }
};
