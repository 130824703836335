import * as React from 'react';
import { Device, WeatherStation, DeviceType } from '../../Interface';
import { ContextConsumer, IContext } from '../../Context';

// Props provenienti da Display.tsx
export interface WeatherContainerProps {
    // changePage: Function; // Funzione updatePage da Display.tsx
    allDevices: Device[]; // Lista di tutti i device
}

interface WeatherContainerState {
    weatherDev?: WeatherStation; // Stato del device Meteo
}

export class WeatherContainer extends React.Component<
    WeatherContainerProps,
    WeatherContainerState
    > {
    constructor(props: WeatherContainerProps) {
        super(props);

        this.state = {
            weatherDev: undefined,
        };

        this.findWeather = this.findWeather.bind(this);
    }

    componentWillReceiveProps(newProps: any) {
        if (this.state.weatherDev) {
            this.setState({
                weatherDev: this.findWeather(),
            });
        }
    }
    // Al montaggio del componente trova la sonda meteo
    componentDidMount() {
        this.setState({
            weatherDev: this.findWeather(),
        });
    }

    // Cerca tra tutti i device la sonda meteo e la salva nello stato.
    private findWeather() {
        let weatherDevice;
        for (let el of this.props.allDevices) {
            if (el.type === DeviceType.weatherStation) {
                weatherDevice = el as WeatherStation;
                break;
            }
        }
        return weatherDevice;
    }

    // Visualizza le informazioni della sonda meteo a seconda dello stato
    private weatherInfo() {
        // Abbiamo le informazioni della sonda meteo.
        if (this.state.weatherDev) {
            const { weatherDev } = this.state;
            return (
                <ContextConsumer>
                    {(context: IContext) => (
                        <React.Fragment>
                            {/* Wind speed*/}
                            {weatherDev.weather.wind ? (
                                <div className="col-lg-4">
                                    <div className="panel panel-warning">
                                        <div className="panel-heading">
                                            Wind
                                        </div>
                                        <div className="panel-body">
                                            {weatherDev.weather.wind.speed ? (
                                                <div>
                                                    Speed{' '}
                                                    {
                                                        weatherDev.weather.wind
                                                            .speed
                                                    }{' '}
                                                    m/s
                                                </div>
                                            ) : null}
                                            {weatherDev.weather.wind
                                                .direction != undefined ? (
                                                    <div>
                                                        Direction{' '}
                                                        {
                                                            (context.i18n as any)[
                                                                context.language
                                                            ].weather.direction[
                                                            weatherDev.weather
                                                                .wind.direction
                                                            ]
                                                        }
                                                    </div>
                                                ) : null}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Temperature */}
                            {weatherDev.weather.temperature ? (
                                <div className="col-lg-4">
                                    <div className="panel panel-warning">
                                        <div className="panel-heading">
                                            Temperature
                                        </div>
                                        <div className="panel-body">
                                            {weatherDev.weather.temperature} °C
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Light intensity */}
                            {weatherDev.weather.globalRadiation ? (
                                <div className="col-lg-4">
                                    <div className="panel panel-warning">
                                        <div className="panel-heading">
                                            Light intensity
                                        </div>
                                        <div className="panel-body">
                                            {
                                                weatherDev.weather
                                                    .globalRadiation
                                            }{' '}
                                            lum
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Rain */}
                            {weatherDev.weather.rain ? (
                                <div className="col-lg-4">
                                    <div className="panel panel-warning">
                                        <div className="panel-heading">
                                            Rain
                                        </div>
                                        <div className="panel-body">
                                            {weatherDev.weather.rain} state
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Humidity */}
                            {weatherDev.weather.relativeHumidity ? (
                                <div className="col-lg-4">
                                    <div className="panel panel-warning">
                                        <div className="panel-heading">
                                            Humidity
                                        </div>
                                        <div className="panel-body">
                                            {
                                                weatherDev.weather
                                                    .relativeHumidity
                                            }{' '}
                                            %
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </React.Fragment>
                    )}
                </ContextConsumer>
            );
        } else {
            // TODO: Mostrare loading indicator
            return <div>TODO: Loading...</div>;
        }
    }

    public render() {
        return <div className="weather-container">{this.weatherInfo()}</div>;
    }
}
