import { createMuiTheme, Theme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
// import {    HttpTransportType,    HubConnection,    HubConnectionBuilder,} from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import Axios from 'axios';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import Spinner from 'react-spinkit';
import { ContextProvider } from '../../Context';
import {
    Alarm,
    Custom,
    Device,
    Error,
    Event,
    HomeConfig,
    Languages,
    MapImage,
    ScenarioInterface,
    SubLevel,
} from '../../Interface';
import variable from '../../styles/variable.scss';
import authService from '../api-authorization/AuthorizeService';
import { Widget } from '../Home/Widgets/Widgets';
import { Display } from './Display';
import { LoginComponent } from './LoginComponent';

// State del componente
interface LayoutState {
    allLevels: SubLevel[]; // Array contenente tutti i Livelli della casa
    allDevices: Device[]; // Array contenente tutti i device della casa
    allAlarms: Alarm[]; // Array contenente tutti gli allarmi della casa
    start: number; // Array contenente gli id dei device da visualizzare come pagina iniziale
    allScenarios: ScenarioInterface[]; // Array contenente gli scenari della casa
    homeConfig: HomeConfig; // Array contenente un oggetto configurazione della casa
    connectionErrors: Error[]; // Array con gli errori da visualizzare in banner e devices
    allPrograms: Event[]; // Array con gli eventi
    images: MapImage[];
    theme: string;
    accent: string;
    lock: boolean;
    language: Languages;
    custom: any;
    widgets: Widget[];
    scenario?: ScenarioInterface; // Scenario per il passaggio da scenario a mappa
    loading: boolean;
    muiTheme?: Theme;
    logged?: boolean;
    email?: string;
    isSidebarOpen: boolean;
}

function stringToLanguages(val: string | null) {
    if (val === 'en') return 'en';
    if (val === 'de') return 'de';
    if (val === 'it') return 'it';
    return 'en';
}


export default class LayoutComponent extends React.Component<any, LayoutState> {
    i18nFile = require('../../i18n/language.json');
    // customFile = require('../../../JsonDefaults/Custom.json');
    // TODO: modifica parse json
    i18nStringa = JSON.stringify(this.i18nFile);
    i18n = JSON.parse(this.i18nStringa);
    // customString = JSON.stringify(this.customFile);
    // custom = JSON.parse(this.customString);
    private connection?: signalR.HubConnection;
    theme = localStorage.getItem('theme') || 'light';
    accent = localStorage.getItem('accent') || 'green';
    language: Languages = stringToLanguages(localStorage.getItem('language'));
    constructor(props: any) {
        super(props);
        this.state = {
            allDevices: [],
            allLevels: [],
            allScenarios: [],
            allPrograms: [],
            allAlarms: [],
            start: 0,
            homeConfig: {
                devices: false,
                scenarios: false,
                programs: false,
                alarms: false,
                weather: false,
                settings: false,
                cleanScreen: false,
                lockScreen: false,
                textMode: false,
                languages: ['en'],
                languageDefault: 'en',
                alarmEmail: null
            },
            connectionErrors: [], // Di default è vuoto
            images: [],
            theme: this.theme,
            accent: this.accent,
            lock: false,
            language: this.language,
            custom: null,
            widgets: [],
            loading: true,
            scenario: undefined,
            isSidebarOpen: false,
        };

        this.addconnectionError = this.addconnectionError.bind(this);
        this.removeConnectionError = this.removeConnectionError.bind(this);
        this.removeAllConnectionErrors = this.removeAllConnectionErrors.bind(this);
        this.setTheme = this.setTheme.bind(this);
        this.setAccent = this.setAccent.bind(this);
        this.setLock = this.setLock.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.setCustom = this.setCustom.bind(this);
        this.setScenario = this.setScenario.bind(this);
        this.setMuiTheme = this.setMuiTheme.bind(this);
        this.setWidgets = this.setWidgets.bind(this);
        this.setImages = this.setImages.bind(this);
        this.setIsSidebarOpen = this.setIsSidebarOpen.bind(this);
    }

    public async postLogin() {
        Axios.defaults.withCredentials = true;

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`/oecomunicationhub`, {
                transport: signalR.HttpTransportType.LongPolling,
            })
            .withAutomaticReconnect()
            .build();
        this.connection.keepAliveIntervalInMilliseconds = 10000;
        this.connection.serverTimeoutInMilliseconds = 120000;

        this.connection.onclose((error) => {
            console.log('React connessione chiusa');
            console.log(error?.message);
            window.location.reload();
        });

        this.connection.onreconnected((error) => {
            console.log('React connessione riaperta', error);
            window.location.reload();
        });

        window.scrollTo(0, 1);
        document.body.className = 'my-bg-primary';
        document.addEventListener('contextmenu', (event) => event.preventDefault());

        //in update prende il nuovo stato dei device e li aggiorna
        this.connection.on('Update', (dataString: string) => {
            this.UpdateDevice(dataString)

            //const data: Device = JSON.parse(dataString);
            //console.log('Received:', data);
            //let ns = [...this.state.allDevices];
            //let device = ns.find((x) => x.id === data.id);

            //if (device) {
            //  var index = ns.indexOf(device);
            //  // ns[index] = data;
            //  Object.assign(ns[index], data);
            //  this.setState({
            //    allDevices: ns,
            //  });
            //}
        });


        // aggiorna la connessione per evitare disconnessioni
        this.connection.on('ConnectionRefresh', (dataString: string) => {
            console.log('Receive ConnectionRefresh from Core');
        });

        this.connection.on('UpdateScenarios', (data: string) => {
            // add code to update props binded to scenario
            console.log('Update scenari', data);
            console.log('Update scenari', JSON.parse(data));
            let newData: ScenarioInterface[] = JSON.parse(data);
            console.log(newData);

            this.setState({
                allScenarios: newData,
            });
        });

        this.connection.on('UpdatePrograms', (data: string) => {
            // add code to update props binded to scenario
            let newData: Event[] = JSON.parse(data);
            this.setState({
                allPrograms: newData,
            });
            console.log('Update programmi');
        });

        this.connection.on('UpdateCustoms', (data: string) => {
            let newCustom: Custom = JSON.parse(data);
            console.log('Custom Update:', newCustom);
            this.setState({
                custom: newCustom,
            });
        });

        this.connection.on('UpdateWidgets', (data: string) => {
            let newWidget: Widget[] = JSON.parse(data);
            console.log('Widget update:', newWidget);

            this.setWidgets(newWidget);
        });

        this.connection.on('UpdateLevels', (data: string) => {
            let home: { startLevel: number; subLevels: SubLevel[] } = JSON.parse(
                data
            );
            console.log('Widget update:', home);

            this.setState({
                allLevels: home.subLevels,
            });
        });

        this.connection.on('RefreshPage', (data: string) => {
            document.body.style.cursor = 'default';
            console.log('Refresh Page');
            window.location.reload();
        });

        this.connection.on('UpdateAlarm', (dataString: string) => {
            const data: Alarm = JSON.parse(dataString);
            let ns = [...this.state.allAlarms];
            let alarm = ns.find((x) => x.id === data.id);

            if (alarm) {
                console.log('Update Alarm');
                var index = ns.indexOf(alarm);
                ns[index] = data;
                this.setState({
                    allAlarms: ns,
                });
            }
        });

        //Recupera i file dei devices e dei livelli dal server e li copia nello stato
        let promiseArray = [
            '/apidevices',
            '/apilevels',
            '/apiscenarios',
            '/apialarms',
            '/apiconfig',
            '/apiprograms',
            // '/apiimages',
            '/apicustom',
            '/apiwidget',
            '/OmniVision/GetEmail',
        ].map((url) => Axios.get(url));
        Axios.defaults.withCredentials = true;
        Axios.all(promiseArray).then(
            Axios.spread(
                (
                    devices,
                    levels,
                    scenarios,
                    resAlarms,
                    config,
                    resPrograms,
                    // resImages,
                    custom,
                    widget,
                    email
                ) => {
                    const dev = devices.data;
                    const home = levels.data;
                    const allScenarios = scenarios.data;
                    const alarms = resAlarms.data;
                    const homeConfig = config.data;
                    const programs = resPrograms.data;
                    // const images = resImages.data;
                    const widgets = widget.data;

                    let defaultConfig: HomeConfig = {
                        devices: true,
                        scenarios: true,
                        programs: true,
                        alarms: true,
                        weather: true,
                        settings: true,
                        cleanScreen: true,
                        lockScreen: true,
                        textMode: true,
                        languages: ['en'],
                        languageDefault: 'en',
                        alarmEmail: null
                    };
                    this.setState(
                        {
                            allDevices: dev || [],
                            allLevels: home.subLevels || [],
                            start: home.startLevel,
                            allScenarios: allScenarios || [],
                            allAlarms: alarms || [],
                            homeConfig: homeConfig || defaultConfig,
                            allPrograms: programs || [],
                            // images: images || [],
                            custom: custom.data,
                            loading: false,
                            widgets: widgets || [],
                            email: email.data,
                        },
                        () =>
                            this.connection
                                ?.start()
                                .then(() =>
                                    this.connection?.invoke('InitConnection', email.data)
                                )
                    );
                }
            )
        );

        setInterval(async () => {
            if (this.connection?.state === signalR.HubConnectionState.Disconnected)
                this.connection?.start();

            // Causa una continua richiesta di refresh di tutti i db da parte del service.
            // non utilizzare
            //if (this.connection?.state === signalR.HubConnectionState.Connected)
            //  this.connection
            //      ?.send('ConnectionRefresh')
            //      .catch((err) => console.error(err));
            //console.log("React ConnectionRefresh");

            console.log("Sent KeepAlive to Core");

            Axios.defaults.withCredentials = true;
            Axios.get('/apiOmniVision/KeepAlive') // .then(console.log)
        }, 10 * 1000);
    }

    public async ConnRefresh() {
        // La fuuzione è chiamata dal pulsante di refresh in SidebarContent.
        // La funzione chiama l'api RefreshAllToServices che invia un "RefreshAll" a tutti i services connessi.
        // L'invio del refresh è abilitato dal setting: RefreshAllToServices
        Axios.defaults.withCredentials = true;
        Axios.get('/apiOmniVision/RefreshAllToServices') // .then(console.log)
        console.log("React Send RefreshAll to all services");
    }

    public async UpdateDevice(dataString: string) {
        // Tentativo di eseguire in background l'aggiornamento della device
        // Comunque ill browser si rallenta al RefreshAll.
        return new Promise<void>((resolve) => {
            const data: Device = JSON.parse(dataString);
            console.log('Received:', data);
            let ns = [...this.state.allDevices];
            let device = ns.find((x) => x.id === data.id);

            if (device) {
                var index = ns.indexOf(device);
                // ns[index] = data;
                Object.assign(ns[index], data);
                this.setState({
                    allDevices: ns,
                }, () => resolve());
            }
        });
    }


    public async componentDidMount() {
        try {
            const authenticated = await authService.isAuthenticated();

            Axios.defaults.withCredentials = true;
            Axios.get('/OmniVision/GetEmail').then(async (res) => {

                this.setState({ logged: res.data != '' });

                if (res.data != '') {
                    await this.postLogin();
                }
            })
                .catch((err) => {
                    console.log(err);
                    this.setState({ logged: false });
                });

        } catch (error) {
            this.setState({ logged: false });
        }

        document.documentElement.style.setProperty(
            '--primary',
            variable[`${this.theme}-primary`]
        );
        document.documentElement.style.setProperty(
            '--secondary',
            variable[`${this.theme}-secondary`]
        );
        document.documentElement.style.setProperty(
            '--accent',
            variable[`${this.accent}`]
        );
        document.documentElement.style.setProperty(
            '--text-color',
            this.theme === 'light' ? '#000000' : '#ffffff'
        );
        moment.locale(this.state.language);
        this.setMuiTheme();
    }

    componentDidUpdate(prevProps: any) {
        // Controllo per quando passo da devices a scenari e viceversa
        if (prevProps.location.pathname.split('/')[1] === 'devices') {
            if (!(this.props.location.pathname.split('/')[1] === 'devices')) {
                this.setLock(false);
            }
        } else {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                this.setLock(false);
            }
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (
                this.props.location.pathname.split('/')[1] !== 'devices' &&
                this.props.location.pathname.split('/').length <= 2
            ) {
                this.setScenario(undefined);
            }
        }
    }
    // Funzione per aggiungere un errore alla lista di errori. Crea un nuovo errore con l'errore che gli viene passato.
    public addconnectionError(error: string, date: string) {
        let errors = _.clone(this.state.connectionErrors);
        let newError = {
            id: this.state.connectionErrors.length + 1, // id univoco
            error: error,
            date: date,
        };

        errors.push(newError);
        this.setState({
            connectionErrors: errors,
        });
    }

    // Funzione per rimuovere un errore dalla lista di errori. Prende un id e rimuove l'errore con quell'id.
    public removeConnectionError(id: number) {
        let errors = _.clone(this.state.connectionErrors);
        let deleteError = errors.find((x) => x.id === id);
        if (deleteError) {
            let index = errors.indexOf(deleteError);
            errors.splice(index, 1);
        }

        this.setState({
            connectionErrors: errors,
        });
    }

    // Funzione per svuotare la lista di errori.
    public removeAllConnectionErrors() {
        this.setState({
            connectionErrors: [],
        });
    }

    private setTheme(newTheme: string) {
        this.setState(
            {
                theme: newTheme,
            },
            this.setMuiTheme
        );
    }

    private setAccent(newAccent: string) {
        this.setState(
            {
                accent: newAccent,
            },
            this.setMuiTheme
        );
    }

    private setIsSidebarOpen(open: boolean) {
        this.setState({
            isSidebarOpen: open,
        });
    }

    private setMuiTheme() {
        const accent = getComputedStyle(document.documentElement)
            .getPropertyValue('--accent')
            .trim();
        const primary = getComputedStyle(document.documentElement)
            .getPropertyValue('--primary')
            .trim();
        const secondary = getComputedStyle(document.documentElement)
            .getPropertyValue('--secondary')
            .trim();
        const text = getComputedStyle(document.documentElement)
            .getPropertyValue('--text-color')
            .trim();
        const materialTheme = createMuiTheme({
            palette: {
                text: {
                    primary: text,
                    hint: text,
                },
                primary: {
                    main: accent,
                },
                secondary: {
                    main: primary,
                },
                action: {
                    active: accent,
                },
                background: {
                    default: primary,
                    paper: secondary,
                },
                type: this.state.theme === 'light' ? 'light' : 'dark',
            },
        });

        this.setState({
            muiTheme: materialTheme,
        });
    }
    private setLock(newLock: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.setState(
                {
                    lock: newLock,
                },
                () => resolve()
            );
        });
    }

    private setLanguage(newLang: Languages) {
        const newConfig: HomeConfig = {
            ...this.state.homeConfig,
            languageDefault: newLang
        };

        Axios.post('/apiconfig', newConfig)
            .then((res) => {
                if (res) {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        this.setState({
            language: newLang,
        });
        moment.locale(newLang);
        localStorage.setItem('language', newLang);
    }

    private setCustom(newCustom: Custom) {
        Axios.post('/apicustom', newCustom)
            .then((res) => {
                console.log(res.status + ' ' + res.statusText);
                if (res.data.error) {
                    console.log('Error: ' + res.status + ' ' + res.data.error);
                } else {
                    this.setState({
                        custom: newCustom,
                    });
                }
            })
            .catch((err) => {
                console.log('Custom updated.');
                console.log(err);
            });
    }

    private setScenario(
        newScenario: ScenarioInterface | undefined
    ): Promise<void> {
        return new Promise((resolve) => {
            this.setState(
                {
                    scenario: newScenario,
                },
                () => resolve()
            );
        });
    }

    public setWidgets(newWidgets: Widget[]): Promise<void> {
        return new Promise((resolve) => {
            this.setState(
                {
                    widgets: newWidgets,
                },
                () => resolve()
            );
        });
    }
    public setImages(images: MapImage[]): Promise<void> {
        return new Promise((resolve) => {
            this.setState(
                {
                    images,
                },
                () => resolve()
            );
        });
    }
    public render() {
        if (!this.state.muiTheme) return <></>;
        const { logged } = this.state;
        if (logged === false) {
            return (
                <ThemeProvider theme={this.state.muiTheme}>
                    <LoginComponent />
                </ThemeProvider>
            );
        }
        return (
            <ThemeProvider theme={this.state.muiTheme}>
                <ContextProvider
                    value={{
                        theme: this.state.theme,
                        setTheme: this.setTheme,
                        accent: this.state.accent,
                        setAccent: this.setAccent,
                        lock: this.state.lock,
                        setLock: this.setLock,
                        i18n: this.i18n,
                        custom: this.state.custom,
                        setCustom: this.setCustom,
                        language: this.state.language,
                        setLanguage: this.setLanguage,
                        allDevices: this.state.allDevices,
                        allLevels: this.state.allLevels,
                        allPrograms: this.state.allPrograms,
                        allScenarios: this.state.allScenarios,
                        allAlarms: this.state.allAlarms,
                        setScenario: this.setScenario,
                        scenario: this.state.scenario,
                        email: this.state.email,
                        isSidebarOpen: this.state.isSidebarOpen,
                        setIsSidebarOpen: this.setIsSidebarOpen,
                        images: this.state.images,
                        setImages: this.setImages,
                        homeConfig: this.state.homeConfig
                    }}
                >
                    {!this.state.loading && (
                        <Display
                            allDevices={this.state.allDevices}
                            allLevels={this.state.allLevels}
                            start={this.state.start}
                            allScenarios={this.state.allScenarios}
                            alarms={this.state.allAlarms}
                            homeConfig={this.state.homeConfig}
                            connectionErrors={this.state.connectionErrors}
                            addconnectionError={this.addconnectionError}
                            removeConnectionError={this.removeConnectionError}
                            removeAllConnectionErrors={this.removeAllConnectionErrors}
                            programs={this.state.allPrograms}
                            setWidgets={this.setWidgets}
                            widgets={this.state.widgets}
                        />
                    )}
                    {this.state.loading && (
                        <div className='loading-indicator'>
                            <Spinner
                                name='line-scale-pulse-out'
                                fadeIn='none'
                                className='color-accent'
                            />
                        </div>
                    )}
                </ContextProvider>
            </ThemeProvider>
        );
    }
}

export const Layout = withRouter((props) => <LayoutComponent {...props} />);


