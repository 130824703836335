import { Container } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import * as React from 'react';
import { Context, IContext } from '../../Context';
import {
  Blind,
  Device,
  DeviceType,
  Light,
  ScenarioInterface,
  statusEnum,
  Wait,
} from '../../Interface';
import { DeviceIcon } from '../Devices/DeviceIcon';
import history from '../history';
import { TitleBar } from '../Layout/TitleBar';
import { DeviceModal } from '../NewDevices/Modals/DeviceModal';
import variable from './../../styles/variable.scss';

// Props del componente
interface ScenarioShowProps {
  scenario: ScenarioInterface; // Scenario da mostrare
  handleIsModify: Function; // Funzione che cambia la modalità da mostra a modifica scenario
  handleDelete: Function; // Funzione per cancellare lo scenario
  handleExecute: Function; // Funzione per eseguire lo scenario
  printPath: Function;
}

interface ScenarioShowState {
  currentDevice?: Device;
}

export class ScenarioShow extends React.Component<
  ScenarioShowProps,
  ScenarioShowState
> {
  constructor(props: ScenarioShowProps) {
    super(props);

    this.renderDevices = this.renderDevices.bind(this);
    this.showDevice = this.showDevice.bind(this);
    this.hideDevice = this.hideDevice.bind(this);
    this.renderPopUp = this.renderPopUp.bind(this);

    this.state = {
      currentDevice: undefined,
    };
  }

  private showTime(time: number) {
    const val = time / 1000;

    const hours = Math.floor(val / 3600).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const minutes = Math.floor((val % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const seconds = ((val % 3600) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `${hours}:${minutes}:${seconds}`;
  }
  // Funzione che renderizza la lista di dispositivi nello scenario.
  private renderDevices() {
    const context = this.context;
    if (this.props.scenario) {
      return this.props.scenario.devices.map((device, index) => {
        let icon: string = '';
        let style: React.CSSProperties | undefined;
        let classname = '';
        let status = statusEnum.ON;
        switch (device.type) {
          case DeviceType.dimLight:
          case DeviceType.rgbLight:
          case DeviceType.light:
            icon = 'fas fa-lightbulb';
            status = (device as Light).light.status;
            break;
          case DeviceType.blind:
          case DeviceType.rotatingBlind:
            const deviceblind = device as Blind;
            icon = 'fas fa-align-justify';
            classname = 'half-icon';
            const accentColor = variable[context.accent];
            const perc = Math.round(deviceblind.blind.position.value / 25) * 25;
            const direction =
              JSON.stringify(deviceblind.icons) === JSON.stringify([81])
                ? 'left'
                : 'top';
            style = {
              background: `-webkit-linear-gradient(
                                    ${direction},
                                    ${accentColor} 0%,
                                    ${accentColor} ${perc}%,
                                    #606060 ${perc}%,
                                    #606060 100%
                                )`,
            };
            break;
          case DeviceType.thermofan:
          case DeviceType.thermostat:
            icon = 'fas fa-thermometer-quarter';
            break;
          case DeviceType.scene:
            icon = 'fas fa-image';
            break;
          case DeviceType.wait:
            icon = 'fas fa-stopwatch';
            break;
        }

        return (
          <div key={'device-list-' + device.id + '-' + index}>
            <div
              className={
                'device-scenario-item ' + context.theme + '-border-bottom'
              }
              onClick={() => this.showDevice(device)}
            >
              <span>
                <span
                  className={`device-scenario-type ${
                    !style ? 'color-accent' : ''
                  }`}
                >
                  <DeviceIcon
                    default={icon}
                    status={status}
                    icons={device.icons}
                    style={style}
                    className={classname}
                  />
                </span>
                <span className='device-scenario-name'>
                  {device.type === DeviceType.wait
                    ? this.showTime((device as Wait).wait)
                    : context.custom[context.language].devices.name[device.id]}
                </span>
              </span>

              {this.props.printPath(device.path)}
            </div>
          </div>
        );
      });
    }
  }

  private showDevice(device: Device) {
    this.setState({
      currentDevice: device,
    });
  }

  private hideDevice() {
    this.setState({
      currentDevice: undefined,
    });
  }

  private renderPopUp() {
    const { currentDevice } = this.state;
    if (currentDevice != undefined) {
      return (
        <DeviceModal
          open={true}
          onClose={this.hideDevice}
          device={currentDevice}
          locked
        />
      );
    }
  }

  public render() {
    const context: IContext = this.context;
    const name =
      context.custom[context.language].scenarios.name[this.props.scenario.id];
    const description =
      context.custom[context.language].scenarios.description[
        this.props.scenario.id
      ];
    console.log(description);

    return (
      <>
        <TitleBar
          title={name || this.props.scenario.name}
          subtitle={
            <>
              {description || this.props.scenario.description}{' '}
              {this.props.scenario.favourite ? (
                <Star color='primary' />
              ) : (
                <StarBorder color='primary' />
              )}
            </>
          }
          onBack={history.goBack}
        />
        <Container style={{ paddingTop: '40px' }}>
          <div className='my-bg-secondary device-scenario-container'>
            {this.renderDevices()}
          </div>

          <div className='scenario-footer'>
            <button
              // bsStyle="default"
              className='my-bg-secondary no-border btn'
              onClick={() => this.props.handleIsModify(context.setLock)}
            >
              <span className='btn-label'>
                <i className='far fa-edit' />
              </span>{' '}
              {context.i18n[context.language].scenarios.modify}
            </button>
            &nbsp;
            {!this.props.scenario.readOnly && (
              <button
                // bsStyle="default"
                className='my-bg-accent-red btn no-border color-white'
                onClick={() => this.props.handleDelete(this.props.scenario)}
              >
                <span className='btn-label'>
                  <i className='far fa-trash-alt' />
                </span>{' '}
                {context.i18n[context.language].scenarios.delete}
              </button>
            )}
            <button
              className='execute-scenario color-accent'
              onClick={() => this.props.handleExecute(this.props.scenario.id)}
            >
              <i className='fas fa-play-circle execute-scenario-button' />
            </button>
          </div>
          {this.renderPopUp()}
        </Container>
      </>
    );
  }
}

ScenarioShow.contextType = Context;
