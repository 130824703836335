import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ContextConsumer, IContext } from '../../Context';

// Props del componente
interface WeatherProps {
}

// State del componente
interface WeatherState { }

// Componente che renderizza la pagina delle preferenze.
export class Weather extends React.Component<WeatherProps, WeatherState> {
    public render() {
        return (
            <ContextConsumer>
                {(context: IContext) => (
                    <React.Fragment>
                        <div className="my-bg-secondary settings-option">
                            <fieldset>
                                <div className="settings-option-title">
                                    {
                                        (context.i18n as any)[context.language]
                                            .settings.weather.outside
                                    }
                                </div>
                                <div className="col-sm-4 col-sm-offset-0">
                                    <Button
                                        // bsClass="btn btn-labeled btn-default mr"
                                        className="my-bg-primary no-border"
                                        onClick={() =>
                                            console.log('delete outside')
                                        }
                                    >
                                        <span className="btn-label">
                                            <i className="far fa-edit" />
                                        </span>{' '}
                                        {
                                            (context.i18n as any)[
                                                context.language
                                            ].settings.weather.delete
                                        }
                                    </Button>
                                </div>
                            </fieldset>
                        </div>
                        <div className="my-bg-secondary settings-option">
                            <fieldset>
                                <div className="settings-option-title">
                                    {
                                        (context.i18n as any)[context.language]
                                            .settings.weather.wind
                                    }
                                </div>
                                <div className="col-sm-4 col-sm-offset-0">
                                    <Button
                                        // bsClass="btn btn-labeled btn-default mr"
                                        className="my-bg-primary no-border"
                                        onClick={() =>
                                            console.log('delete wind')
                                        }
                                    >
                                        <span className="btn-label">
                                            <i className="far fa-edit" />
                                        </span>{' '}
                                        {
                                            (context.i18n as any)[
                                                context.language
                                            ].settings.weather.delete
                                        }
                                    </Button>
                                </div>
                            </fieldset>
                        </div>
                        <div className="my-bg-secondary settings-option">
                            <fieldset>
                                <div className="settings-option-title">
                                    {
                                        (context.i18n as any)[context.language]
                                            .settings.weather.brightness
                                    }
                                </div>
                                <div className="col-sm-4 col-sm-offset-0">
                                    <Button
                                        // bsClass="btn btn-labeled btn-default mr"
                                        className="my-bg-primary no-border"
                                        onClick={() =>
                                            console.log('delete brightness')
                                        }
                                    >
                                        <span className="btn-label">
                                            <i className="far fa-edit" />
                                        </span>{' '}
                                        {
                                            (context.i18n as any)[
                                                context.language
                                            ].settings.weather.delete
                                        }
                                    </Button>
                                </div>
                            </fieldset>
                        </div>
                        <div className="my-bg-secondary settings-option">
                            <fieldset>
                                <div className="settings-option-title">
                                    {
                                        (context.i18n as any)[context.language]
                                            .settings.weather.umidity
                                    }
                                </div>
                                <div className="col-sm-4 col-sm-offset-0">
                                    <Button
                                        // bsClass="btn btn-labeled btn-default mr"
                                        className="my-bg-primary no-border"
                                        onClick={() =>
                                            console.log('delete umidity')
                                        }
                                    >
                                        <span className="btn-label">
                                            <i className="far fa-edit" />
                                        </span>{' '}
                                        {
                                            (context.i18n as any)[
                                                context.language
                                            ].settings.weather.delete
                                        }
                                    </Button>
                                </div>
                            </fieldset>
                        </div>
                    </React.Fragment>
                )}
            </ContextConsumer>
        );
    }
}
