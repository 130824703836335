import * as React from 'react';
import { Context, IContext } from '../../Context';

// Props del componente
interface ToolsProps {
}

// Componente che renderizza la pagina delle preferenze.
export const Tools: React.SFC<ToolsProps> = (props) => {
    const context: IContext = React.useContext(Context);
    return (
        <React.Fragment>
            <div className="my-bg-secondary settings-option">
                <div className="settings-option-title">
                    {context.i18n[context.language].settings.tools.systemStatus}
                </div>
                <div className="settings-option-title">
                    {context.i18n[context.language].settings.tools.log}
                </div>
            </div>
        </React.Fragment>
    );
};
