import Axios from 'axios';
import { boolean } from 'yup';

export class AuthorizeService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _user = '';
    _isAuthenticated = false;
    _popUpDisabled = true;

    async isAuthenticated() {
        Axios.defaults.withCredentials = true;
        Axios.get('/OmniVision/GetEmail').then((res) => {                
            
         if(res.data != '')
         {
            this._user=res.data;
            this._isAuthenticated=true;
            this.notifySubscribers();
            return true;
         }
         else
         {
             this._user='';
            this._isAuthenticated=false;
            this.notifySubscribers();
            return false;
         }

        })
            .catch((err) => {
                console.log(err);
            this._isAuthenticated=false;
            this._user='';
            this.notifySubscribers();
                return false;
            });
    }

    // async getUser() {
    //     if (this._user == '') {
    //         Axios.defaults.withCredentials = true;
    //         Axios.get('/OmniVision/GetEmail').then((res) => {                
                
    //            this._isAuthenticated = true;
    //            this._user = res.data;
    //             this.updateState(res.data);

    //         })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }

    //     return this._user;
    // }

    // async completeSignIn(url) {
    //     try {
            // await this.ensureUserManagerInitialized();
            // const user = await this.userManager.signinCallback(url);
            // this.updateState(user);
            // return this.success(user && user.state);
    //         this.getUser();
    //       return this.isAuthenticated();
    //     } catch (error) {
    //         console.log('There was an error signing in: ', error);
    //         return this.error('There was an error signing in.');
    //     }
    // }

    async signOut() {
        Axios.defaults.withCredentials = true;
        Axios.get('OmniVision/auth/logout');

        setTimeout(() => {
                window.location.replace('/');
        }, 3000);
    }

    // updateState(user) {
    //     this._user = user;
    //     this._isAuthenticated = this._user != '';
    //     this.notifySubscribers();
    // }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    // createArguments(state) {
    //     return { useReplaceToNavigate: true, data: state };
    // }

    // error(message) {
    //     return { status: AuthenticationResultStatus.Fail, message };
    // }

    // success(state) {
    //     return { status: AuthenticationResultStatus.Success, state };
    // }

    // redirect() {
    //     return { status: AuthenticationResultStatus.Redirect };
    // }

    async ensureUserManagerInitialized() {
        // if (this.userManager !== undefined) {
        //     return;
        // }

        // let response = await fetch(`/OmniVision/GetClientRequestParameters`);
        // if (!response.ok) {
        //     throw new Error(`Could not load settings for '${ApplicationName}'`);
        // }

        // let settings = await response.json();
        // settings.automaticSilentRenew = true;
        // settings.includeIdTokenInSilentRenew = true;
        // settings.userStore = new WebStorageStateStore({ prefix: ApplicationName });

        // this.userManager = new UserManager(settings);

        // this.userManager.events.addUserSignedOut(async () => {
        //     await this.userManager.removeUser();
        //     this.updateState(undefined);
        // });
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

// export const AuthenticationResultStatus = {
//     Redirect: 'redirect',
//     Success: 'success',
//     Fail: 'fail'
// };
